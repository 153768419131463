import { Helmet } from 'react-helmet-async';
import { domainConfig } from "../../../assets/config.js";

const HelmetStyle = ({ appState }) => {
    let maxWidthGame = appState.memoryGame.cards.width * appState.memoryGame.cards.cardsPerRow
    
    return (
        <Helmet>
            <style>
                {`      
                        html {
                            --textSideColor: ${appState.apresentation.textSide?.textColor};
                        }
                        .modalPresenter .image-side {
                            flex: ${appState.apresentation.imageSide?.value};
                        }
                        .modalPresenter{
                            flex-direction: ${appState.apresentation.imageSide?.position === 'start' ? 'row' : 'row-reverse'};
                        }
                        .modalPresenter .text-side{
                            flex: ${appState.apresentation.textSide?.value};
                            background-color: ${appState.apresentation.textSide?.backgroundColor};
                        } 

                         .modalPresenter .form-control,
                         .modalPresenter label,
                         .modalPresenter p,
                         .modalPresenter .p,
                         .modalPresenter .btn {
                            font-size: ${appState.apresentation.textSide?.p}rem!important;
                        } 
                        .modalPresenter h5,
                        .modalPresenter .h5 {
                            font-size: ${appState.apresentation.textSide?.h5}rem!important;
                        } 
                            
                        html {
                        --borderRadius: ${appState.memoryGame.cards.borderRadius};
                        --paddingCard: ${appState.memoryGame.cards.padding}px;
                        --textSideColor: ${appState.apresentation.textSide.textColor};
                        }
                        .memoryGameBox .cardFlip {
                            max-width: ${appState.memoryGame.cards.width}px;
                        }    
                        .firstPlayerColorImage {
                            border-radius: 50%;
                            width: 65px;
                            height: 65px;
                            background-color: ${appState.memoryGame.cards.firstPlayerBackgroundColor};
                        }
                        .firstPlayerColorCard {
                            color: ${appState.memoryGame.cards.firstPlayerColor};
                            background-color: ${appState.memoryGame.cards.firstPlayerBackgroundColor};
                        }
                        .secondPlayerColorCard {
                            color: ${appState.memoryGame.cards.secondPlayerColor};
                            background-color: ${appState.memoryGame.cards.secondPlayerBackgroundColor};
                        }
                        .secondPlayerColorImage {
                            border-radius: 50%;
                            width: 65px;
                            height: 65px;
                            background-color: ${appState.memoryGame.cards.secondPlayerBackgroundColor};
                        }
                        .flippedFirstPlayer {
                            outline: 4px solid ${appState.memoryGame.cards.firstPlayerBackgroundColor};
                        }
                        .flippedSecondPlayer {
                            outline: 4px solid ${appState.memoryGame.cards.secondPlayerBackgroundColor};
                        }
                        .flip-card img {
                            border-radius: ${appState.memoryGame.cards.borderRadius};
                        }
                        .flip-card-inner {
                            border-radius: ${appState.memoryGame.cards.borderRadius};
                        }

                        .memoryGameBox {
                            max-width: ${maxWidthGame}px;
                        }
                    `}
            </style>
            {appState.apresentation.showQRCode ? (
                <style>
                    {`
                        .modalQRCodeShare {
                            top: ${appState.apresentation.showQRCode.position.y === 'top' ? ("10px") : ("")};
                            left: ${appState.apresentation.showQRCode.position.x === 'left' ? ("10px") : ("")};
                            bottom: ${appState.apresentation.showQRCode.position.y === 'bottom' ? ("10px") : ("")};
                            right: ${appState.apresentation.showQRCode.position.x === 'right' ? ("10px") : ("")};
                        }
                        `}
                </style>
            ) : ("")}
            {appState.apresentation.imageSide.background && appState.apresentation.imageSide.background !== "" ? (
                <style>
                    {`
                        .modalPresenter .image-side {
                            background-image: url("${domainConfig.imageServer + "/customizacao/" + appState.customizacao_id + "/" + appState.apresentation.imageSide.background}");
                        }
                        `}
                </style>
            ) : ("")}
        </Helmet>
    )
};

export default HelmetStyle;