import React, { useReducer, useContext, useState, useEffect } from 'react';
import { formatInTimeZone } from "date-fns-tz";
import { io } from 'socket.io-client';
import axios from 'axios'
import HelmetStyle from '../components/HelmetStyle.js'
import { useGlobalState } from '../../../hooks/useCustomization.js';
import { domainConfig } from "../../../assets/config.js"
import { convertDate } from "../../../components/Functions"

let socket

const AppContext = React.createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case 'CONNECTED':
      return {
        ...state,
        isConnected: action.payload,
      }
    case 'setContexto':
      return {
        ...state,
        contexto: action.payload,
      }
    case 'setUserHistory':
      return {
        ...state,
        userHistory: action.payload,
      }
    case 'addUserHistory':
      return {
        ...state,
        userHistory: [...state.userHistory, action.payload]
      }
    case 'setValidado':
      return {
        ...state,
        validado: action.payload,
      }
    case 'setCurrentPrize':
      return {
        ...state,
        currentPrize: action.payload,
      }
    case 'setParticipants':
      return {
        ...state,
        participants: action.payload,
      }
    case 'setParticipantsValidate':
      return {
        ...state,
        participantsValidate: action.payload,
      }
    case 'setResults':
      return {
        ...state,
        results: action.payload,
      }
    default:
      return state;
  }
};

const GameProvider = ({ children }) => {
  const { dataApplication } = useGlobalState();
  const [appState, dispatch] = useReducer(reducer, dataApplication.data);
  const [updateData, setUpdateData] = useState(0)
  const [showPresentation, setShowPresentation] = useState(false)
  const [showQRCode, setShowQRCode] = useState(false)
  const [prizeDrawType, setPrizeDrawType] = useState('DrawDefault')
  const [checkQRCodeResult, setCheckQRCodeResult] = useState(false)
  const [drawResult, setDrawResult] = useState()
  const [drawPage, setDrawPage] = useState()
  const [isPlaying, setIsPlaying] = useState(true)

  const updateContext = () => {
    axios.get(`/api/eventos/prizeDraw?evento_id=${dataApplication.evento_id}&customizacao_id=${appState.customizacao_id}`)
      .then(function (response) {
        dispatch({
          type: 'setContexto', payload: response.data.message.context
        });
      }).catch(function (error) {
        console.log(error)
      })
  }

  function registerData(values, actions) {
    let newContext = JSON.parse(JSON.stringify(appState.contexto))
    if (values.names) {
      values.names = values.names.split(/\r?\n/);
    }
    const dateX = new Date();
    const now = formatInTimeZone(dateX, "UTC", "yyyy-MM-dd HH:mm:ss");
    let result = []
    let configs = {
      id: 'id-' + Date.now().toString(36) + Math.random().toString(36).substr(2),
      type: values.type,
      prize: values.prize,
      title: values.title,
      data: now,
      total_draw_times: values.total_draw_times,
      min_number: values.min_number,
      max_number: values.max_number,
      names: values.names
    }

    switch (configs.type) {
      case 'DrawDefault':
        let participants = JSON.parse(JSON.stringify(appState.participantsValidate));
        if (configs.total_draw_times > participants.length) {
          actions.setFieldError('total_draw_times', 'A quantidade de sorteios não pode ser maior que o total de participantes')
          actions.setSubmitting(false)
          return
        }
        for (let i = 0; i < configs.total_draw_times; i++) {
          const array = new Uint32Array(1);
          window.crypto.getRandomValues(array);
          const randomIndex = Math.floor(array[0] / (0xFFFFFFFF + 1) * participants.length);
          if (participants[randomIndex]) {
            participants[randomIndex].participantDetails.visitante_id = participants[randomIndex].visitante_id
            result.push(participants[randomIndex].participantDetails);
            participants.splice(randomIndex, 1);
          }
        }
        newContext.draw = { 'removeWinners': values.removeWinners, 'result': result }
        break;
      case 'DrawName':
        let namesArray = configs.names
        for (let i = 0; i < configs.total_draw_times; i++) {
          const array = new Uint32Array(1);
          window.crypto.getRandomValues(array);
          const randomIndex = Math.floor(array[0] / (0xFFFFFFFF + 1) * namesArray.length);
          result.push(namesArray[randomIndex]);
          namesArray.splice(randomIndex, 1);
        }
        newContext.draw = result
        break;
      case 'DrawNumber':
        let rangeSize = configs.max_number - configs.min_number + 1;
        let generatedNumbers = new Set();
        for (let i = 0; i < configs.total_draw_times; i++) {
          let unique = false;
          let randomIndex;
          while (!unique) {
            const array = new Uint32Array(1);
            window.crypto.getRandomValues(array);
            randomIndex = Math.floor(array[0] / (0xFFFFFFFF + 1) * rangeSize) + configs.min_number;
            if (!generatedNumbers.has(randomIndex)) {
              unique = true;
              generatedNumbers.add(randomIndex);
              result.push(randomIndex);
            }
          }
        }
        newContext.draw = result
        break;
      default:
        break;
    }
    configs.result = result
    socket.emit('setContexto', newContext, configs);
  }

  useEffect(() => {
    if (dataApplication && dataApplication.presenter) {
      socket = io(domainConfig.socketAPI + '?aplicativo_id=' + dataApplication.data.aplicativo_id + '&customizacao_id=' + dataApplication.data.customizacao_id, {
        extraHeaders: {
          'x-event-id': dataApplication.evento_id, // Include event_id in headers
        },
        autoConnect: true,
        withCredentials: true
      });
      socket.on('connect', () => {
        console.log('connect')
        dispatch({ type: 'CONNECTED', payload: true });
      });
      socket.on('login', (result) => {
        dispatch({ type: 'setContexto', payload: result });
      });
      socket.on('disconnect', () => {
        console.log('disconnect')
        dispatch({ type: 'CONNECTED', payload: false });
      });
      socket.on('updateData', () => {
        setUpdateData((prevState) => prevState + 1)
      });
      socket.on('checkQRCode', (result) => {
        setCheckQRCodeResult(result)
      });
      socket.on('setContexto', (result, openModal = false) => {
        dispatch({ type: 'setContexto', payload: result });
        if (openModal === 'openModal' && showPresentation) {
          setShowPresentation(true)
        }
        updateContext()
      });
      socket.on('getData', (result) => {
        result.contexto = JSON.parse(result.contexto)
        let data = processParticipants(appState.prizeDrawType, result.participants, result.results, result.contexto)
        dispatch({ type: 'setResults', payload: data.results });
        dispatch({ type: 'setParticipants', payload: data.participants });
        dispatch({ type: 'setParticipantsValidate', payload: data.validate });
      });
      socket.emit('login');
      return () => {
        if (socket) {
          socket.disconnect();
          console.log('Socket disconnected');
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppContext.Provider value={{
      appState, dispatch, showPresentation, updateData, setUpdateData, showQRCode, setShowQRCode,
      prizeDrawType, setPrizeDrawType, setShowPresentation, registerData, drawResult, setDrawResult,
      isPlaying, setIsPlaying, checkQRCodeResult, setCheckQRCodeResult, setDrawPage, drawPage
    }}>
      <HelmetStyle appState={appState} />
      {children}
    </AppContext.Provider>
  );
};

const getData = () => {
  socket.emit('getData');
};

const updateData = () => {
  socket.emit('updateData');
};

const updateContexto = (newContext) => {
  socket.emit('updateContexto', newContext);
};

const checkQRCode = (id) => {
  socket.emit('checkQRCode', id);
};

export {
  AppContext,
  GameProvider,
  updateData,
  getData,
  updateContexto,
  checkQRCode
};

export const useAppState = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppState must be used within a GlobalStateProvider');
  }
  return context;
};

function processParticipants(prizeDrawType, participants, history, contexto) {
  let winnerSet = []
  let validate = []
  let newHistory = JSON.parse(JSON.stringify(history))

  newHistory = newHistory.map(historyItem => JSON.parse(historyItem.json));

  participants.forEach(participant => {
    participant.participantDetails = JSON.parse(participant.json);
    const transformedDetails = {};
    participant.data_visitacao = convertDate(participant.data_visitacao)
    for (const key in participant.participantDetails) {
      const detail = participant.participantDetails[key];
      transformedDetails[detail.label] = detail.value;
    }
    Object.assign(participant, transformedDetails);
    participant.history = []

    newHistory.forEach(historyItem => {
      if (!historyItem.dataConverted) {
        historyItem.data = convertDate(historyItem.data);
        historyItem.dataConverted = true;
      }
      if (historyItem.type === 'DrawDefault') {
        historyItem.result.forEach(element => {
          if (participant.visitante_id === element.visitante_id) {
            participant.history.push(historyItem);
            winnerSet.push(element.visitante_id);
          }
        })
      }
    });
    if (contexto.draw && contexto.draw.removeWinners) {
      const winner = winnerSet.find(visitante_id => visitante_id === participant.visitante_id);
      if (!winner || winner === undefined) {
        if (prizeDrawType === 2) {
          if (participant.validado === 1) {
            validate.push(participant)
          }
        } else {
          validate.push(participant)
        }
      }
    } else {
      if (prizeDrawType === 2) {
        if (participant.validado === 1) {
          validate.push(participant)
        }
      } else {
        validate.push(participant)
      }
    }
  });

  return { 'participants': participants, 'validate': validate, 'results': newHistory };
}
