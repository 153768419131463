

import React, { useState, useEffect, useRef, useMemo, useContext } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { formatInTimeZone } from "date-fns-tz";
import { Navigation } from 'swiper/modules';
import QRCode from "react-qr-code";
import { Tooltip, OverlayTrigger, Row, Col, Pagination, Form } from 'react-bootstrap'
import axios from 'axios'
import { AnimatePresence, motion } from "framer-motion"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowLeft,
    faSquareCheck,
    faCheckCircle,
    faThumbsDown,
    faThumbsUp,
    faStar,
} from "@fortawesome/free-solid-svg-icons";
import { faStar as faRegularStar, faSquare } from '@fortawesome/free-regular-svg-icons';
import NotyfContext from "../../contexts/NotyfContext.js";
import { NavbarPresenter } from './components/Navbar.js'
import { useGlobalState } from '../../hooks/useCustomization.js';
import { domainConfig } from "../../assets/config.js"
import { useAppState, getData, updateData, selectIndex } from "./context/AppContext.js"
import { PageLoader } from "../../components/Elements"
import { TextWithLineBreaks, convertDate } from "../../components/Functions.js"
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const QAdmin = () => {
    const { dataApplication, handleExit, blockScreen } = useGlobalState();
    const { appState, updateMessages } = useAppState();
    const [pageIndex, setPageIndex] = useState(0);
    const [filter, setFilter] = useState('waiting')

    const setFilterHandler = (value) => {
        setFilter(value)
        setPageIndex(0)
    }

    useEffect(() => {
        if (appState.isConnected) {
            getData()
            const intervalId = setInterval(() => {
                getData()
            }, 10000);
            return () => clearInterval(intervalId);
        }
        if (appState.apresentation.textSide.fontFamily && appState.apresentation.textSide.fontFamily !== dataApplication.customizacao.fontFamily) {
            const link = document.createElement('link');
            link.rel = 'stylesheet';
            link.href = `https://fonts.googleapis.com/css2?family=${appState.apresentation.textSide.fontFamily.replace(/ /g, '+')}&display=swap`;
            document.head.appendChild(link);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateMessages, appState.isConnected]);

    if (appState.messages && appState.messages.notApproved) {
        if (!blockScreen) {
            return (
                <>
                    <motion.nav
                        className="position-fixed w-100 px-3 justify-content-between navbar navbar-expand"
                        key={'appsNavbar'}
                        initial={{ y: -100 }}
                        animate={{ y: 0 }}
                        transition={{ duration: 0.5 }}
                        exit={{ y: -100 }}>
                        <NavbarPresenter />
                    </motion.nav>
                    <motion.div className='presenter-box container' key="mainDiv" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                        <div className="box">
                            <div className="logoBox">
                                <div>
                                    <div className="backButton" onClick={() => handleExit()}><FontAwesomeIcon icon={faArrowLeft} size={'lg'} /></div>
                                </div>
                                <div className="logoBoxChildMiddle">
                                    <div className="pb-3">
                                        <h5 className="mb-1"><TextWithLineBreaks text={appState.titulo}></TextWithLineBreaks></h5>
                                        <span className="badge primaryColor primaryColorText mb-5 fw-normal">{appState.nomeAplicativo}</span>
                                    </div>
                                </div>
                                <div className="logoBoxHideButton">
                                    <div className="backButton"></div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="btn-group mb-3 presenterTab" role="group" aria-label="Large button group">
                                    <button type="button" onClick={() => setFilterHandler('notApproved')} className={`btn ${filter === 'notApproved' ? 'active' : ''}`}>Reprovado ({appState.messages.notApproved.length})</button>
                                    <button type="button" onClick={() => setFilterHandler('waiting')} className={`btn ${filter === 'waiting' ? 'active' : ''}`}>Esperando aprovação ({appState.messages.waiting.length})</button>
                                    <button type="button" onClick={() => setFilterHandler('approved')} className={`btn ${filter === 'approved' ? 'active' : ''}`}>Aprovado ({appState.messages.approved.length})</button>
                                    <button type="button" onClick={() => setFilterHandler('answered')} className={`btn ${filter === 'answered' ? 'active' : ''}`}>Respondido ({appState.messages.answered.length})</button>
                                </div>
                                <div>
                                    <AnimatePresence mode="wait">
                                        <Questions key={filter + pageIndex} filter={filter} pageIndex={pageIndex} setPageIndex={setPageIndex}></Questions>
                                    </AnimatePresence>
                                </div>
                            </div>
                        </div>
                    </motion.div >
                </>
            )
        } else {
            return (
                <div className='default-box'>
                    <div className={`box-full`}>
                        <img src={domainConfig.imageServer + "/evento/" + dataApplication.evento_id + "/" + dataApplication.customizacao.logo} alt="Logo" className="App-logo"></img>
                        <p>Essa tela só está disponível<br></br>para dispositivos maiores que 600px</p>
                    </div>
                </div>
            )
        }
    } else {
        return (
            <motion.div className='default-box' key={'loaderDiv'}>
                <PageLoader color={dataApplication.customizacao.primaryColor} width={75}></PageLoader>
            </motion.div>
        )
    }
}

const Questions = ({ filter, pageIndex, setPageIndex }) => {
    const { dataApplication, setLoading } = useGlobalState();
    const { appState } = useAppState();

    // Pagination state
    const [pageSize, setPageSize] = useState(10);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const messages = appState.messages[filter] || [];

    const pageCount = useMemo(() => Math.ceil(messages.length / pageSize), [messages.length, pageSize]);

    const currentPageMessages = useMemo(() => {
        const start = pageIndex * pageSize;
        return messages.slice(start, start + pageSize);
    }, [pageIndex, pageSize, messages]);

    const canPreviousPage = pageIndex > 0;
    const canNextPage = pageIndex < pageCount - 1;

    // Pagination handlers
    const gotoPage = (page) => setPageIndex(page);
    const previousPage = () => setPageIndex((old) => Math.max(old - 1, 0));
    const nextPage = () => setPageIndex((old) => Math.min(old + 1, pageCount - 1));

    const selectCardType = (data) => {
        if (data.aprovado === 2) {
            return (
                <div className="cardType">
                    <OverlayTrigger
                        placement={"bottom"}
                        overlay={<Tooltip>Aprovar</Tooltip>}
                    >
                        <button className="btn btn-sm primaryColor primaryColorText mx-1" onClick={(e) => toggleMessageStatus(e, 'aprovado', 1, data.mensagem_id, setLoading)} >
                            <FontAwesomeIcon className={`vertically-centered`} icon={faThumbsUp} />
                        </button>
                    </OverlayTrigger>
                </div>
            )
        } else if (data.lido === 0 && data.aprovado === 1) {
            return (
                <div className="cardType">
                    <OverlayTrigger
                        placement={"bottom"}
                        overlay={<Tooltip>Favorito</Tooltip>}
                    >
                        <button className="btn btn-sm primaryColor primaryColorText mx-1" onClick={(e) => toggleMessageStatus(e, 'favorito', data.favorito === 0 ? 1 : 0, data.mensagem_id, setLoading)} >
                            <FontAwesomeIcon className={`vertically-centered`} icon={data.favorito === 0 ? faRegularStar : faStar} />
                        </button>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement={"bottom"}
                        overlay={<Tooltip>Marcar como lido</Tooltip>}
                    >
                        <button className="btn btn-sm primaryColor primaryColorText mx-1" onClick={(e) => toggleMessageStatus(e, 'lido', data.lido === 0 ? 1 : 0, data.mensagem_id, setLoading)} >
                            <FontAwesomeIcon className={`vertically-centered`} icon={data.lido === 0 ? faSquare : faSquareCheck} />
                        </button>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement={"bottom"}
                        overlay={<Tooltip>Reprovar</Tooltip>}
                    >
                        <button className="btn btn-sm primaryColor primaryColorText mx-1" onClick={(e) => toggleMessageStatus(e, 'aprovado', 2, data.mensagem_id, setLoading)}>
                            <FontAwesomeIcon className={`vertically-centered`} icon={faThumbsDown} />
                        </button>
                    </OverlayTrigger>
                </div>
            )
        } else if (data.lido === 0 && data.aprovado === 0) {
            return (
                <div className="cardType">
                    <OverlayTrigger
                        placement={"bottom"}
                        overlay={<Tooltip>Aprovar</Tooltip>}
                    >
                        <button className="btn btn-sm primaryColor primaryColorText mx-1" onClick={(e) => toggleMessageStatus(e, 'aprovado', 1, data.mensagem_id, setLoading)}>
                            <FontAwesomeIcon className={`vertically-centered`} icon={faThumbsUp} />
                        </button>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement={"bottom"}
                        overlay={<Tooltip>Reprovar</Tooltip>}
                    >
                        <button className="btn btn-sm primaryColor primaryColorText mx-1" onClick={(e) => toggleMessageStatus(e, 'aprovado', 2, data.mensagem_id, setLoading)}>
                            <FontAwesomeIcon className={`vertically-centered`} icon={faThumbsDown} />
                        </button>
                    </OverlayTrigger>
                </div>
            )
        } else if (data.lido === 1 && data.aprovado === 1) {
            return (
                <div className="cardType">
                    <OverlayTrigger
                        placement={"bottom"}
                        overlay={<Tooltip>Favorito</Tooltip>}
                    >
                        <button className="btn btn-sm primaryColor primaryColorText mx-1" onClick={(e) => toggleMessageStatus(e, 'favorito', data.favorito === 0 ? 1 : 0, data.mensagem_id, setLoading)}>
                            <FontAwesomeIcon className={`vertically-centered`} icon={data.favorito === 0 ? faRegularStar : faStar} />
                        </button>
                    </OverlayTrigger>
                </div>
            )
        }
    }

    const SelectMessage = (key) => {
        if (currentPageMessages[key].lido === 0 && currentPageMessages[key].aprovado === 1) {
            selectIndex(currentPageMessages[key].mensagem_id)
        } else {
            return
        }
    }

    return (
        <>
            <motion.div className="row" key={filter} initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: 0.25 } }}
                exit={{ opacity: 0 }}>
                {currentPageMessages && currentPageMessages.length > 0 ? (
                    <AnimatePresence layout LayoutRoot>
                        {currentPageMessages && Object.keys(currentPageMessages).map(key => (
                            <motion.div key={'cardContainer' + currentPageMessages[key].mensagem_id} className="col-6 mb-3"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                layout>
                                <div className="cardContainer" onClick={() => SelectMessage(key)}>
                                    <div className="cardMessage">
                                        {appState.contexto.currentQuestion === currentPageMessages[key].mensagem_id ? (
                                            <div className="cardTypeSelected">Selecionado</div>
                                        ) : (
                                            <div className="cardTypeDate">{convertDate(currentPageMessages[key].data)}</div>
                                        )}
                                        <p className="mt-4 pt-2">{currentPageMessages[key].message}</p>
                                        {selectCardType(currentPageMessages[key])}
                                        {dataApplication.customizacao.form.fieldsToShow && dataApplication.customizacao.form.fieldsToShow.map((inputID, index) => {
                                            const field = dataApplication.customizacao.form.fields.find(field => field.inputID === inputID);
                                            if (field && currentPageMessages[key].participantDetails && currentPageMessages[key].participantDetails[field.inputID]) {
                                                const isLast = index === dataApplication.customizacao.form.fieldsToShow.length - 1;
                                                return (
                                                    <span className={`badge primaryColor primaryColorText fw-normal mb-2 ${isLast ? '' : 'me-2'}`} key={'field' + index}>
                                                        {currentPageMessages[key].participantDetails[field.inputID].value}
                                                    </span>
                                                );
                                            }
                                            return null; // Handle cases where the field is not found (optional)
                                        })}
                                    </div>
                                </div>
                            </motion.div>
                        ))}
                    </AnimatePresence>
                ) : (
                    <p className="mt-5">Não há informações disponíveis</p>
                )}
            </motion.div>
            <Row className="text-start">
                <Col md="6">
                    <span className="mx-2">
                        Página{" "}
                        <span>
                            {pageIndex + 1} de {pageCount}
                        </span>
                    </span>
                    <span className="ms-3 me-2">Mostrar:</span>
                    <Form.Select
                        className="d-inline-block w-auto"
                        value={pageSize}
                        onChange={(e) => {
                            setPageSize(Number(e.target.value));
                        }}
                    >
                        {[10, 20, 30, 40, 50].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                        ))}
                    </Form.Select>
                </Col>
                <Col md="6">
                    <Pagination className="float-end">
                        <Pagination.First
                            onClick={() => gotoPage(0)}
                            disabled={!canPreviousPage}
                        />
                        <Pagination.Prev
                            onClick={() => previousPage()}
                            disabled={!canPreviousPage}
                        />
                        <Pagination.Next
                            onClick={() => nextPage()}
                            disabled={!canNextPage}
                        />
                        <Pagination.Last
                            onClick={() => gotoPage(pageCount - 1)}
                            disabled={!canNextPage}
                        />
                    </Pagination>
                </Col>
            </Row>
        </>
    )
}

export const QAModal = () => {
    const { dataApplication } = useGlobalState();
    const { appState, setShowPresentation, getQuestionIndex } = useAppState();
    const swiperRef = useRef(null);
    const notyf = useContext(NotyfContext);

    useEffect(() => {
        notyf.open({
            type: "success",
            message: "Para fechar o modal basta utilizar o 'ESC'",
            ripple: true,
            duration: 5000,
            dismissible: true,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape' || event.keyCode === 27) { // Check if the "End" key was pressed
                setShowPresentation(false);
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSlideChange = (swiper) => {
        let mensagem_id = appState.messages.approved[swiper.activeIndex].mensagem_id
        selectIndex(mensagem_id)
    };

    const goToSlide = () => {
        if (swiperRef.current) {
            let index = getQuestionIndex(appState.contexto.currentQuestion)
            swiperRef.current.slideTo(getQuestionIndex(index));
        }
    };

    useEffect(() => {
        goToSlide()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appState.contexto.currentQuestion])

    return (
        <>
            {appState.apresentation.showQRCode && appState.apresentation.showQRCode.status && <div className="modalQRCodeShare">
                <div>
                    {appState.apresentation.showQRCode.text !== '' && <p className="mb-2">{appState.apresentation.showQRCode.text}</p>}
                    <QRCode className="bg-white p-3 qrcodeContainer" value={appState.apresentation.showQRCode.link !== '' ? appState.apresentation.showQRCode.link : domainConfig.aplicativos + '/?token=' + dataApplication.token} />
                </div>
            </div>}
            <div className="logoBox">
                <div className="logoBoxChildMiddle">
                    <img src={domainConfig.imageServer + "/evento/" + dataApplication.evento_id + "/" + dataApplication.customizacao.logo} alt="Logo" className="App-logo pb-0"></img>
                    {appState.apresentation.title.status && <h5 className="my-5"><TextWithLineBreaks text={appState.apresentation.title.value}></TextWithLineBreaks></h5>}
                </div>
            </div>
            {appState.messages.approved.length > 0 ? (
                <Swiper
                    onSwiper={(swiper) => (swiperRef.current = swiper)}
                    loop={false}
                    initialSlide={getQuestionIndex()}
                    onSlideChange={(swiper) => handleSlideChange(swiper)}
                    navigation={true}
                    modules={[Navigation]}
                    className="messagesSlider"
                >
                    {Object.keys(appState.messages.approved).map(key => (
                        <SwiperSlide key={'Slide' + key} data-id={appState.messages.approved[key].mensagem_id}>
                            <SwiperSlideText totalMessages={appState.messages.approved.length} data={appState.messages.approved[key]} index={key} key={appState.messages.approved[key].mensagem_id} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            ) : (
                <div className="swiper-noSlide">
                    <h5>Não foi encontrado nenhum resultado</h5>
                </div>
            )}
        </>
    )
}

export const SwiperSlideText = ({ totalMessages, data, index }) => {
    const { dataApplication } = useGlobalState()
    const { appState } = useAppState();
    const [isAnswered, setIsAnswered] = useState(data.lido === 1 ? true : false);

    function handleClick(e, data) {
        setIsAnswered(!isAnswered);
        setTimeout(() => {
            toggleMessageStatus(e, 'lido', 1, data.mensagem_id)
        }, 750);
    }

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
        >
            <h5 className="questionCounter">{parseInt(index) + 1}/{totalMessages}</h5>
            <div className="messagesContainer">
                <p className="messagesSize">
                    {data.message}
                </p>
                {appState.apresentation.showAuthor && dataApplication.customizacao.form.fieldsToShow &&
                    <div className="mt-4">
                        {dataApplication.customizacao.form.fieldsToShow.map((inputID, index) => {
                            const field = dataApplication.customizacao.form.fields.find(field => field.inputID === inputID);
                            if (field && data.participantDetails && data.participantDetails[field.inputID]) {
                                return (
                                    <p className="mb-0 modalDataFields" key={'modalDataFields' + index}>
                                        {data.participantDetails[field.inputID].label}: <span>{data.participantDetails[field.inputID].value}</span>
                                    </p>
                                );
                            }
                            return null; // Handle cases where the field is not found (optional)
                        })}
                    </div>}
            </div>
            <div className="QAnswerFooter">
                <AnimatePresence mode="wait">
                    <motion.button
                        key={'anwsered' + isAnswered}
                        className={`btn ${isAnswered ? "QAnswerFooterCheck" : "QAnswerFooterNotCheck"}`}
                        onClick={(e) => handleClick(e, data)}
                        initial={{ opacity: 1 }}
                        animate={{ opacity: 1, transition: { duration: 0.5 } }}
                        exit={{ opacity: 0 }}
                    >
                        {isAnswered &&
                            <motion.span
                                className="me-2"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1, transition: { duration: 0.5 } }}
                                end={{ opacity: 0 }}
                            >
                                <FontAwesomeIcon icon={faCheckCircle} size={'lg'} />
                            </motion.span>
                        }
                        {isAnswered ? "Respondida" : "Marcar como respondida"}
                    </motion.button>
                </AnimatePresence>
            </div>
        </motion.div>
    )
}

const toggleMessageStatus = (e, type, value, mensagem_id, setLoading) => {
    if (setLoading) {
        setLoading(true)
    }
    e.preventDefault();
    e.stopPropagation();
    let updateDataJson = {};
    updateDataJson[type] = value
    if (type === 'lido') {
        if (value === 1) {
            const dateX = new Date();
            const now = formatInTimeZone(dateX, "UTC", "yyyy-MM-dd HH:mm:ss");
            updateDataJson['data_lido'] = now
        } else {
            updateDataJson['data_lido'] = null
        }

    }
    axios.put(`/api/eventos/messagesDisplay/${mensagem_id}`, updateDataJson)
        .then(function (response) {
            updateData()
        })
        .catch(function (error) {
            console.log(error);
        });
};

export default QAdmin
