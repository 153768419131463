

import React, { useState, useEffect, useRef, useCallback, useContext } from 'react'
import { formatInTimeZone } from "date-fns-tz";
import { Tabs, Tab, Modal, Row, Form, Col } from 'react-bootstrap'
import axios from 'axios'
import { AnimatePresence, motion } from "framer-motion"
import Webcam from "react-webcam";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import QRCode from "react-qr-code";
import StartAnimation from "../10/components/StartAnimation.js";
import {
    faArrowLeft,
    faCheck,
    faCircle,
    faClose,
    faExclamationTriangle,
    faAddressCard
} from "@fortawesome/free-solid-svg-icons";
import { NavbarPresenter } from './components/Navbar.js'
import { useGlobalState } from '../../hooks/useCustomization.js';
import { domainConfig } from "../../assets/config.js"
import { useAppState, getData, postResult } from "./context/AppContext.js"
import { PageLoader, PageLoaderDots } from '../../components/Elements.js'
import NotyfContext from "../../contexts/NotyfContext.js";
import { FormComponent } from '../../Register.js'
import { TextWithLineBreaks, dataURItoBlob } from "../../components/Functions.js"

const PhotoboothAdmin = () => {
    const { dataApplication, handleExit, blockScreen } = useGlobalState();
    const { appState, updateData } = useAppState();

    useEffect(() => {
        if (appState.isConnected) {
            getData()
            const intervalId = setInterval(() => {
                getData()
            }, 15000);
            return () => clearInterval(intervalId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateData, appState.isConnected]);

    if (appState.participants) {
        if (!blockScreen) {
            return (
                <>
                    <motion.nav
                        className="position-fixed w-100 px-3 justify-content-between navbar navbar-expand"
                        key={'appsNavbar'}
                        initial={{ y: -100 }}
                        animate={{ y: 0 }}
                        transition={{ duration: 0.5 }}
                        exit={{ y: -100 }}>
                        <NavbarPresenter />
                    </motion.nav>
                    <motion.div className='presenter-box container' key="mainDiv" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                        <div className="box">
                            <div className="logoBox">
                                <div>
                                    <div className="backButton" onClick={() => handleExit()}><FontAwesomeIcon icon={faArrowLeft} size={'lg'} /></div>
                                </div>
                                <div className="logoBoxChildMiddle">
                                    <div className="pb-3">
                                        <h5 className="mb-1"><TextWithLineBreaks text={appState.titulo}></TextWithLineBreaks></h5>
                                        <span className="badge primaryColor primaryColorText mb-5 fw-normal">{appState.nomeAplicativo}</span>
                                    </div>
                                </div>
                                <div className="logoBoxHideButton">
                                    <div className="backButton"></div>
                                </div>
                            </div>
                            <Tabs
                                defaultActiveKey="k1"
                                transition={true}
                                id="noanim-tab-example"
                                className="mb-4"
                            >
                                <Tab eventKey="k1" title={`Configurações`}>
                                    <Painel></Painel>
                                </Tab>
                            </Tabs>
                        </div>
                    </motion.div >
                </>
            )
        } else {
            return (
                <div className='default-box'>
                    <div className={`box-full`}>
                        <img src={domainConfig.imageServer + "/evento/" + dataApplication.evento_id + "/" + dataApplication.customizacao.logo} alt="Logo" className="App-logo"></img>
                        <p>Essa tela só está disponível<br></br>para dispositivos maiores que 600px</p>
                    </div>
                </div>
            )
        }
    } else {
        return (
            <motion.div className='default-box' key={'loaderDiv'}>
                <PageLoader color={dataApplication.customizacao.primaryColor} width={75}></PageLoader>
            </motion.div>
        )
    }
}

const Painel = () => {
    const { appState, dispatch } = useAppState();
    const [devices, setDevices] = React.useState([]);
    const [facingModes] = React.useState(['user', 'environment', 'left', 'right']);
    const [updateConfigs, setUpdateConfigs] = useState(0)

    const handleDevices = React.useCallback(
        mediaDevices =>
            setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")),
        [setDevices]
    );

    React.useEffect(
        () => {
            navigator.mediaDevices.enumerateDevices().then(handleDevices);
        },
        [handleDevices]
    );

    return (
        <Row className="mt-4 mx-0 text-start">
            <Col md={6}>
                <CameraComponentPanel key={'cameraComponent' + updateConfigs}></CameraComponentPanel>
            </Col>
            <Col md={6}>
                <Form.Group as={Col} className='mb-3'>
                    <Form.Label>Dispositivo</Form.Label>
                    <Form.Select
                        name={'deviceId'}
                        onChange={(e) => {
                            dispatch({
                                type: 'camera', payload: { 'type': 'deviceId', 'value': e.target.value }
                            });
                            setUpdateConfigs(prevState => prevState + 1)
                        }}
                        value={appState.camera?.deviceId}>
                        {devices.map((device, index) => {
                            return (
                                <option key={'deviceId' + index} value={device.deviceId}>{device.label || `Device ${index + 1}`}</option>
                            );
                        })}
                    </Form.Select>
                </Form.Group>
                <Form.Group as={Col} className='mb-3'>
                    <Form.Label>Modo da câmera</Form.Label>
                    <Form.Select
                        name={'facingMode'}
                        value={appState.camera?.facingMode}
                        onChange={(e) => {
                            dispatch({
                                type: 'camera', payload: { 'type': 'facingMode', 'value': e.target.value }
                            });
                        }}
                    >
                        {facingModes.map((modes, index) => {
                            return (
                                <option key={'facingMode' + index} value={modes}>{modes}</option>
                            );
                        })}
                    </Form.Select>
                </Form.Group>
            </Col>
        </Row>
    )
}

export const PhotoboothGalleryModal = () => {
    const { dataApplication } = useGlobalState()
    const [mediaItems, setMediaItems] = useState([]);
    const [updateGallery, setUpdateGallery] = useState(0);
    const { appState, setShowPresentationGallery } = useAppState();

    const variants = {
        hidden: { x: '100vw', opacity: 0 },
        visible: { x: 0, opacity: 1, transition: { duration: 0.5 } },
        exit: { x: '-100vw', opacity: 0, transition: { duration: 0.5 } },
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape' || event.keyCode === 27) {
                setShowPresentationGallery(false);
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [setShowPresentationGallery]);

    useEffect(() => {
        const preloadImages = (imageUrls) => {
            return Promise.all(
                imageUrls.map((url) => {
                    return new Promise((resolve, reject) => {
                        const img = new Image();
                        img.src = url;
                        img.onload = () => resolve(url); // Return the URL if loaded successfully
                        img.onerror = () => {
                            console.error(`Failed to load image: ${url}`);
                            resolve(null); // Resolve with null to avoid rejecting the entire Promise.all
                        };
                    });
                })
            );
        };

        const updateMediaItems = async () => {
            try {
                const shuffled = appState.participants.sort(() => 0.5 - Math.random());
                const selectedMedia = shuffled.slice(0, 4).map(item => item.media);
                const preloadedMedia = await preloadImages(selectedMedia);

                // Filter out null values in case of loading errors
                const validMedia = preloadedMedia.filter(media => media !== null);

                setMediaItems(validMedia);
                setUpdateGallery(prevState => prevState + 1);
            } catch (error) {
                console.error('Error updating media items:', error);
            }
        };

        updateMediaItems();
        const interval = setInterval(updateMediaItems, parseInt(appState.apresentation.configs.galleryTransition));
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appState.participants]);

    const isVideo = (src) => {
        const videoExtensions = ['mp4', 'webm', 'ogg'];
        console.log(src)
        const extension = src.split('.').pop().toLowerCase();
        return videoExtensions.includes(extension);
    };

    return (
        <AnimatePresence mode="wait" initial={false}>
            {mediaItems.length === 0 ? (
                <motion.div className='default-box'>
                    <PageLoader color={dataApplication.customizacao.primaryColor} width={75}></PageLoader>
                </motion.div>
            ) : (
                <motion.div
                    key={updateGallery}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    variants={variants}
                    style={{ display: 'flex', flexWrap: 'wrap', height: '100vh', width: '100vw' }}
                >
                    {mediaItems.map((src, index) => {
                        return (
                            <div key={index}
                                style={{ flex: '1 1 50%', overflow: 'hidden' }}
                            >
                                {isVideo(src) ? (
                                    <video
                                        className="mediaItemsImg"
                                        src={src}
                                        autoPlay
                                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                    />
                                ) : (
                                    <img
                                        className="mediaItemsImg"
                                        src={src}
                                        alt={`Random ${index}`}
                                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                    />
                                )}
                            </div>
                        )
                    })}
                </motion.div>
            )
            }

        </AnimatePresence>
    );
};


export const PhotoboothModal = () => {
    const [step, setStep] = useState(0)
    const [showTip, setShowTip] = useState(false);
    const iframeRef = useRef(null);

    return (
        <AnimatePresence mode="wait" initial={false}>
            <iframe ref={iframeRef} style={{ display: 'none' }} title="Print iframe" />
            <StepComponent key={'StepComponent' + step} step={step} setStep={setStep} showTip={showTip} setShowTip={setShowTip} iframeRef={iframeRef}></StepComponent>
        </AnimatePresence>
    )
}

const StepComponent = ({ step, setStep, showTip, setShowTip, iframeRef }) => {
    const { dataApplication } = useGlobalState()
    const { appState, image, video, setVideo, props, lastUser, setLastUser, setShowPresentation, setProps, setImage, formValues, setFormValues } = useAppState();
    const [countdown, setCountdown] = useState()
    const [showModalAuthorize, setShowModalAuthorize] = useState(true)
    const [showModalLastUser, setShowModalLastUser] = useState(false)
    const [mediaPreview, setMediaPreview] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false);
    const formikRef = useRef(null);
    const notyf = useContext(NotyfContext);
    const stepRef = useRef()


    function saveFormData(values, actions) {
        actions.setSubmitting(false);
        setFormValues(values)
        setStep((lastStep) => lastStep + 1)
    }

    function handleEndStep() {
        setImage(false)
        setMediaPreview(false)
        setVideo(false)
        setStep(0)
        setFormValues()
    }

    const submitFormExternally = () => {
        if (formikRef.current) {
            formikRef.current.submitForm();
        }
    };

    const handleRefreshButton = () => {
        setMediaPreview(null);
        setImage(null);
        setCountdown(false)
    }

    function registerUser(values) {
        let newArray = { ...values }
        let json = {}
        if (newArray) {
            if (newArray.authorization) {
                delete newArray.authorization
            }
            Object.entries(newArray).forEach(entry => {
                const field = dataApplication.customizacao.form.fields.find(field => field.inputID === parseInt(entry[0]));
                json[entry[0]] = { 'label': field.label, 'value': entry[1] }
            })
        }
        json = JSON.stringify(json)
        axios.post("/api/eventos/register", {
            evento_id: dataApplication.evento_id,
            token: dataApplication.token,
            json: json
        }).then(function (response) {
            setLastUser(response.data.message.encripted)
            postResult(props, response.data.message.visitante_id)
        }).catch(function (error) {
            notyf.open({
                type: "danger",
                message: 'Houve um erro no registro do usuário',
                ripple: true,
                dismissible: true,
            });
        })
    }

    const saveMedia = (file, type) => {
        const formData = new FormData();
        formData.append("file", file);
        if (appState.apresentation.configs.leonardoai && appState.apresentation.configs.leonardoai.status === true) {
            formData.append("leonardoai", JSON.stringify(appState.apresentation.configs.leonardoai));
        }
        axios
            .post(`/api/upload/evento/${dataApplication.evento_id}`, formData)
            .then((response) => {
                let url = domainConfig.imageServer + '/evento/' + dataApplication.evento_id + '/' + response.data.message
                if (response.data.generationId) {
                    setProps(prevProps => ({
                        ...prevProps,
                        media: url,
                        leonardoai: response.data.generationId,
                        uploadId: response.data.uploadId
                    }));
                } else {
                    setProps(prevProps => ({
                        ...prevProps,
                        media: url
                    }));
                }
            })
            .catch((error) => {
                console.log(error);
                notyf.open({
                    type: "error",
                    message: error.response?.data?.message ? error.response?.data?.message : 'Houve um erro com o servidor',
                    ripple: true,
                    duration: 30000,
                    dismissible: true,
                });
            });
    }

    const handleModalClick = (event) => {
        event.stopPropagation();
        setShowModalAuthorize(false)
    };

    const handleCountdown = () => {
        if (showModalAuthorize) {
            return
        }
        setCountdown(true)
    }

    const handlePrint = (file) => {
        if (!file || !iframeRef.current) return; // Ensure file and iframe are available
        // Convert file to URL
        const imgURL = URL.createObjectURL(file);
        // Access the iframe's document and write content
        const doc = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
        doc.open();
        doc.write(`
          <html>
            <head>
              <title>Print Image</title>
              <style>
                @media print {
                  @page {
                    margin: 0;
                  }
                  body, html {
                    margin: 0;
                    padding: 0;
                    height: 100%;
                    width: 100%;
                  }
                  img {
                    width: 100vw;
                    height: 100vh;
                    object-fit: contain;
                  }
                }
              </style>
            </head>
            <body onload="window.print(); window.close();">
              <img src="${imgURL}" alt="Image to Print" />
            </body>
          </html>
        `);
        doc.close();
    };

    useEffect(() => {
        if (step === 0 && props.media !== null) {
            setProps({ 'media': null, 'id': null })
        }
        if (image && step === 2) {
            let photo = dataURItoBlob(image);
            const imagefile = new File([photo], appState.titulo + '.jpeg', { type: 'image/jpeg' });
            saveMedia(imagefile, 'image');
        }
        if (video && step === 2) {
            const videofile = new File([video], appState.titulo + '.webm', { type: "video/webm" });
            saveMedia(videofile, 'video');
        }
        if (step === 3) {
            if (image && appState.apresentation.configs.printPhoto) {
                let photo = dataURItoBlob(image);
                const imagefile = new File([photo], appState.titulo + '.jpeg', { type: 'image/jpeg' });
                handlePrint(imagefile)
            }
            const currentStep = step;
            stepRef.current = currentStep;
            const timeoutId = setTimeout(() => {
                if (stepRef.current === currentStep) {
                    handleEndStep();
                }
            }, appState.apresentation.configs.qrcodeEndPage);

            return () => clearTimeout(timeoutId); // Clean up the timeout if the effect is cleaned up
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step]);

    useEffect(() => {
        if (showTip === false) {
            setShowTip(true)
            notyf.open({
                type: "success",
                message: "Utilize a tecla 'K' para prosseguir entre as páginas e 'ESC' para voltar",
                ripple: true,
                duration: 5000,
                dismissible: true,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    useEffect(() => {
        if (props.media && step === 2) {
            if (props.id) {
                setStep((lastStep) => lastStep + 1)
            } else {
                registerUser(formValues)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape' || event.key === 'Escape' || event.keyCode === 27) {
                setShowPresentation(false);
            }
            if (event.key === 'k') {
                if (showModalAuthorize === true && step === 0) {
                    setShowModalAuthorize(false)
                } else if ((!image || !video) && step === 0 && !mediaPreview) {
                    setCountdown(true)
                } else if ((image || video) && step === 0) {
                    if (appState.apresentation.configs.form && appState.apresentation.configs.form === 1 && step === 0) {
                        setStep((lastStep) => lastStep + 1)
                    } else {
                        setStep((lastStep) => lastStep + 2)
                    }
                } else if (step === 3) {
                    handleEndStep()
                }
            } else if (event.key === 'i') {
                if (step === 0) {
                    handleRefreshButton()
                }
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModalAuthorize, image, video, step, mediaPreview]);

    switch (step) {
        default:
        case 0:
            return (
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                    {appState.apresentation.configs.showLastUser && lastUser && <button type="button" className="btn primaryColor primaryColorText userHistoryButton" onClick={(e) => { e.preventDefault(); e.stopPropagation(); setShowModalLastUser(true) }}>
                        <FontAwesomeIcon icon={faAddressCard} size={'xl'} />
                    </button>}
                    {appState.apresentation.configs.media === 'video' ? (
                        <VideoComponent countdown={countdown} setCountdown={setCountdown} handleRefreshButton={handleRefreshButton} step={step} setStep={setStep} mediaPreview={mediaPreview} setMediaPreview={setMediaPreview} handleCountdown={handleCountdown} />
                    ) : (
                        <PhotoComponent countdown={countdown} setCountdown={setCountdown} handleRefreshButton={handleRefreshButton} step={step} setStep={setStep} mediaPreview={mediaPreview} setMediaPreview={setMediaPreview} handleCountdown={handleCountdown} />
                    )}
                    <Modal show={showModalAuthorize}
                        style={{ zIndex: 1056 }}
                        size="lg"
                        backdrop="static"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>
                        <Modal.Header className="justify-content-center" onHide={(() => setShowModalAuthorize(false))}>
                            <Modal.Title>Lei Geral de Proteção de Dados</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <h6 className="fw-bold"><FontAwesomeIcon className="me-1 text-warning" icon={faExclamationTriangle} size={'xl'} /> Lei nº 12.965/2014</h6>
                            <p>Este app e seus respectivos envolvidos estão ciente dos termos das normas de proteção de dados aplicáveis à espécie, notadamente a Lei nº 12.965/2014
                                (o "Marco Civil da Internet") e Lei nº 13.709/2018 (a "LGPD"), e se comprometem a respeitá-las e cumpri-las.</p>
                            <div className="text-center">
                                <button type="button" className="btn primaryColor primaryColorText mt-3" onClick={handleModalClick}>
                                    Autorizo o uso da minha imagem
                                </button>
                            </div>
                        </Modal.Body>
                    </Modal>
                    {appState.apresentation.configs.showLastUser && lastUser && <Modal show={showModalLastUser}
                        style={{ zIndex: 1056 }}
                        size="md"
                        backdrop="static"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>
                        <Modal.Header closeButton className="justify-content-center" onHide={(() => setShowModalLastUser(false))}>
                            <Modal.Title>Último QRCode gerado</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="text-center my-4">
                            <QRCode key={'qrcode'} className="bg-white p-2 qrcodeContainer" value={domainConfig.aplicativos + "/?token=" + dataApplication.token + "&encryptedData=" + lastUser.encryptedData + "&iv=" + lastUser.iv} />
                        </Modal.Body>
                    </Modal>}

                </motion.div>
            )
        case 1:
            return (
                <motion.div className='larger-box pointer' initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                    <div className="photoboothLogo">
                        <img src={domainConfig.imageServer + "/evento/" + dataApplication.evento_id + "/" + dataApplication.customizacao.logo} alt="Logo" className="App-logo"></img>
                    </div>
                    <div className={`box-full`}>
                        <div className="text-center">
                            {appState.apresentation.register && appState.apresentation.register.title !== '' && <h5 className="mb-5"><TextWithLineBreaks text={appState.apresentation.register.title}></TextWithLineBreaks></h5>}
                        </div>
                        <FormComponent formikRef={formikRef} registerUser={saveFormData} onSubmittingStateChange={setIsSubmitting}></FormComponent>
                        <div className="text-center mt-3">
                            <button className="btn primaryColor btn-lg primaryColorText" type="submit" disabled={isSubmitting} onClick={submitFormExternally}>
                                {!isSubmitting ? dataApplication.customizacao.form.buttonText : <PageLoaderDots color={dataApplication.customizacao.primaryColorText} width="25" padding={0} />}
                            </button>
                        </div>
                    </div>
                </motion.div>
            )
        case 2:
            return (
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                    <div className="default-box w-100 h-100 loadingContainer">
                        <img src={domainConfig.imageServer + "/evento/" + dataApplication.evento_id + "/" + dataApplication.customizacao.logo} alt="Logo" className="App-logo mb-5"></img>
                        <PageLoader color={dataApplication.customizacao.primaryColor} width={75}></PageLoader>
                    </div>
                </motion.div>
            )
        case 3:
            return (
                <motion.div className='default-box pointer' initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} onClick={() => handleEndStep()}>
                    <div className="photoboothLogo">
                        <img src={domainConfig.imageServer + "/evento/" + dataApplication.evento_id + "/" + dataApplication.customizacao.logo} alt="Logo" className="App-logo"></img>
                    </div>
                    <div className={`box-full`}>
                        <div className="mb-4 text-center">
                            {appState.apresentation.endPage.title && appState.apresentation.endPage.title !== '' && <div className="col"><h5 className="mb-3"><TextWithLineBreaks text={appState.apresentation.endPage.title}></TextWithLineBreaks></h5></div>}
                            {appState.apresentation.endPage.description && appState.apresentation.endPage.description !== '' && <div className="col"><p><TextWithLineBreaks text={appState.apresentation.endPage.description}></TextWithLineBreaks></p></div>}
                        </div>
                        <QRCode key={'qrcode'} className="bg-white p-2 qrcodeContainer mb-5" value={domainConfig.aplicativos + "/?token=" + dataApplication.token + "&encryptedData=" + props.id.encryptedData + "&iv=" + props.id.iv} />
                        <div className="cameraButtonContainerEnd">
                            <div className="cardPhotoButton">
                                <h5 className="mb-0">{appState.apresentation.endPage.buttonText}</h5>
                            </div>
                        </div>
                    </div>
                </motion.div>
            )
    }
}

const VideoComponent = ({ countdown, handleRefreshButton, step, setStep, mediaPreview, setMediaPreview, handleCountdown }) => {
    const { appState, setVideo, video } = useAppState();
    const webcamRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const recordedChunks = useRef([]);
    const [timeLeft, setTimeLeft] = useState(0)
    const [showTimer, setShowTimer] = useState(false)
    const [capturing, setCapturing] = useState(false);

    const handleStartCaptureClick = useCallback(() => {
        setTimeLeft(appState.apresentation.configs.videoDuration)
        setShowTimer(true)
        setCapturing(true);
        mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
            mimeType: "video/webm"
        });
        mediaRecorderRef.current.addEventListener(
            "dataavailable",
            handleDataAvailable
        );
        mediaRecorderRef.current.addEventListener(
            "stop",
            handleStop
        );
        mediaRecorderRef.current.start();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [webcamRef, setCapturing, mediaRecorderRef]);

    const handleDataAvailable = useCallback(
        ({ data }) => {
            if (data.size > 0) {
                recordedChunks.current = recordedChunks.current.concat(data)
            }
        },
        []
    );

    const handleStop = useCallback(() => {
        if (recordedChunks.current.length) {
            const blobX = new Blob(recordedChunks.current, {
                type: "video/webm"
            });
            const url = URL.createObjectURL(blobX);
            setVideo(blobX); // Assuming setVideo is a function to handle the Blob
            setMediaPreview(url); // Assuming setMediaPreview is a function to handle the URL

            //Error on video conversion

            // convertBlobToMp4(blobX, domainConfig.imageServer + "/customizacao/" + appState.customizacao_id + "/" + appState.apresentation.frame)
            //     .then(blob => {
            //         const url = URL.createObjectURL(blob);
            //         setVideo(blob); // Assuming setVideo is a function to handle the Blob
            //         setMediaPreview(url); // Assuming setMediaPreview is a function to handle the URL
            //     })
            //     .catch(error => {
            //         notyf.open({
            //             type: "danger",
            //             message: 'Houve um erro na geração de media',
            //             ripple: true,
            //             dismissible: true,
            //         });
            //         console.log(error);
            //     })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleStopCaptureClick = useCallback(() => {
        mediaRecorderRef.current.stop();
        setCapturing(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mediaRecorderRef, webcamRef, setCapturing]);

    useEffect(() => {
        if (capturing) {
            const intervalId = setInterval(() => {
                if (timeLeft > 0) {
                    setTimeLeft(timeLeft - 1)
                }
            }, 1000)

            if (timeLeft === 0) {
                setTimeout(() => {
                    handleStopCaptureClick();
                }, 1000);
            }
            return () => clearInterval(intervalId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [capturing, timeLeft])

    useEffect(() => {
        // Esta função de limpeza é chamada quando o componente é desmontado
        return () => {
            if (video) {
                URL.revokeObjectURL(video);
            }
        };
    }, [video]);

    return (
        <AnimatePresence mode="wait">
            {mediaPreview ? (
                <motion.div key={'photocam'} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                    <div className="cameraContainerPreview">
                        <video loop muted autoPlay src={mediaPreview} alt="videocam" />
                    </div>
                    <div className="cameraButtonContainerPreview">
                        <button type="button" className="btn primaryColor primaryColorText photoButton mx-3" onClick={() => handleRefreshButton()}>
                            <FontAwesomeIcon icon={faClose} size={'xl'} />
                        </button>
                        <button type="button" className="btn primaryColor primaryColorText photoButton mx-3" onClick={() => appState.apresentation.configs.form && appState.apresentation.configs.form === 1 && step === 0 ? setStep((lastStep) => lastStep + 2) : setStep((lastStep) => lastStep + 1)}>
                            <FontAwesomeIcon icon={faCheck} size={'xl'} />
                        </button>
                    </div>
                </motion.div>
            ) : (
                <motion.div key={'webcam'} className={!countdown ? "pointer" : ""} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} onClick={() => handleCountdown()}>
                    <div className="cameraContainer">
                        {countdown && !capturing &&
                            <motion.div key={'StartAnimation' + capturing} className={`photoboothAnimation`} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                                <StartAnimation startMedia={handleStartCaptureClick}></StartAnimation>
                            </motion.div>}
                        {showTimer &&
                            <motion.div key={'timeLeft'} className={`photoboothTimer`} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                                <FontAwesomeIcon className='text-danger me-3 mt-1' icon={faCircle} /><span>00:{timeLeft < 10 ? '0' + timeLeft : timeLeft}</span>
                            </motion.div>}
                        <Webcam
                            key={'webcam3' + appState.camera?.deviceId}
                            audio={appState.camera?.audio}
                            ref={webcamRef}
                            videoConstraints={{ deviceId: appState.camera?.deviceId, facingMode: appState.camera?.facingMode }} />
                    </div>
                    {!countdown && <div className="cameraButtonContainer">
                        <div className="cardPhotoButton">
                            <h5 className="mb-0">{appState.apresentation.firstPage.buttonText}</h5>
                        </div>
                    </div>}
                </motion.div>
            )}
        </AnimatePresence>
    )
}

const PhotoComponent = ({ countdown, handleRefreshButton, step, setStep, mediaPreview, setMediaPreview, handleCountdown }) => {
    const { appState, setImage } = useAppState();
    const webcamRef = React.useRef(null);

    const screenshot = () => {
        const imageSrc = webcamRef.current.getScreenshot();
        setMediaPreview(imageSrc);
        const img = new Image();
        const frameImg = new Image();
        frameImg.crossOrigin = "anonymous";
        img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            if (appState.apresentation.configs.leonardoai && appState.apresentation.configs.leonardoai.status === true) {
                canvas.width = img.naturalWidth;
                canvas.height = img.naturalHeight;
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height); // Draw the image without scaling
            } else {
                // Draw the image with the frame
                canvas.width = frameImg.width; // Frame dimensions
                canvas.height = frameImg.height;

                const scale = Math.max(canvas.width / img.naturalWidth, canvas.height / img.naturalHeight);
                const imgWidth = img.naturalWidth * scale; // New width and height after scaling
                const imgHeight = img.naturalHeight * scale;
                const offsetX = (canvas.width - imgWidth) / 2; // Center the image
                const offsetY = (canvas.height - imgHeight) / 2;

                // Draw the captured image and the frame
                ctx.drawImage(img, offsetX, offsetY, imgWidth, imgHeight);
                ctx.drawImage(frameImg, 0, 0, canvas.width, canvas.height);
            }

            // Export the final image
            const finalImageSrc = canvas.toDataURL('image/png');
            setImage(finalImageSrc);
        };

        if (appState.apresentation.configs.leonardoai && appState.apresentation.configs.leonardoai.status === true) {
            img.src = imageSrc;
        } else {
            frameImg.onload = () => {
                img.src = imageSrc;
            };
            if (window.innerWidth > window.innerHeight && appState.apresentation.frameHorizontal) {
                frameImg.src = domainConfig.imageServer + "/customizacao/" + appState.customizacao_id + "/" + appState.apresentation.frameHorizontal;
            } else {
                frameImg.src = domainConfig.imageServer + "/customizacao/" + appState.customizacao_id + "/" + appState.apresentation.frame;
            }
        }
    };

    return (
        <AnimatePresence mode="wait">
            {mediaPreview ? (
                <motion.div key={'photocam'} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                    {appState.apresentation.configs.media === 'image' && <div className="framePreview" style={{ backgroundImage: `url(${domainConfig.imageServer}/customizacao/${appState.customizacao_id}/${(window.innerWidth > window.innerHeight && appState.apresentation.frameHorizontal) ? appState.apresentation.frameHorizontal : appState.apresentation.frame})` }}></div>}
                    <div className="cameraContainerPreview">
                        <img src={mediaPreview} alt="photocam" />
                    </div>
                    <div className="cameraButtonContainerPreview">
                        <button type="button" className="btn primaryColor primaryColorText photoButton mx-3" onClick={() => handleRefreshButton()}>
                            <FontAwesomeIcon icon={faClose} size={'xl'} />
                        </button>
                        <button type="button" className="btn primaryColor primaryColorText photoButton mx-3" onClick={() => appState.apresentation.configs.form && appState.apresentation.configs.form === 1 && step === 0 ? setStep((lastStep) => lastStep + 1) : setStep((lastStep) => lastStep + 2)}>
                            <FontAwesomeIcon icon={faCheck} size={'xl'} />
                        </button>
                    </div>
                </motion.div>
            ) : (
                <motion.div key={'webcam'} className={!countdown ? "pointer" : ""} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} onClick={() => handleCountdown()}>
                    <div className="cameraContainer">
                        {countdown &&
                            <motion.div key={'StartAnimation'} className={`photoboothAnimation`} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                                <StartAnimation startMedia={screenshot}></StartAnimation>
                            </motion.div>}
                        <Webcam
                            key={'webcam2' + appState.camera?.deviceId}
                            ref={webcamRef}
                            audio={appState.camera?.audio}
                            screenshotFormat={appState.camera?.screenshotFormat}
                            videoConstraints={{ deviceId: appState.camera?.deviceId, facingMode: appState.camera?.facingMode }} />
                    </div>
                    {!countdown && <div className="cameraButtonContainer">
                        <div className="cardPhotoButton">
                            <h5 className="mb-0">{appState.apresentation.firstPage.buttonText}</h5>
                        </div>
                    </div>}
                </motion.div>
            )}
        </AnimatePresence>
    )
}

const CameraComponentPanel = () => {
    const { appState } = useAppState();
    const webcamRef = React.useRef(null);

    return (
        <div className="webcamContainerPreview">
            <Webcam
                key={'webcam' + appState.camera?.deviceId}
                ref={webcamRef}
                height={'360px'}
                widht={'720px'}
                videoConstraints={{ deviceId: appState.camera?.deviceId, facingMode: appState.camera?.facingMode }} />
        </div>
    )
}

export default PhotoboothAdmin
