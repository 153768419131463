import React from 'react'
import { AnimatePresence } from "framer-motion"
import { useGlobalState } from '../../hooks/useCustomization';
import { useAppState } from './context/AppContext.js'
import StartAnimation from "./components/StartAnimation.js"
import BaseLayout from "../../components/BaseLayout"
import PlayerSelect from "./components/PlayerSelect"
import Game, { PlayerCard } from "./components/Game.js"
import End from "./components/End.js"
import Ranking from "./components/Ranking.js"

const MemoryGame = () => {
    const { dataApplication, handleExit } = useGlobalState();
    const { appState, start, setStart, definitions, score, currentPlayer } = useAppState();

    return (
        <>
            <AnimatePresence mode={'wait'}>
                {(() => {
                    switch (start) {
                        case 0:
                            return (
                                <BaseLayout key={start} showBackButton={dataApplication.apps.length > 1 || appState.reset?.status ? true : false} backButtonHandler={() => handleExit()} >
                                    <PlayerSelect key={start} />
                                </BaseLayout>
                            )
                        case 1:
                            return (
                                <StartAnimation key={start} timer={5} value={2} />
                            )
                        case 2:
                            return (
                                <BaseLayout key={start} box={'full-box'} showBackButton={true} backButtonHandler={() => setStart(0)} >
                                    <Game />
                                </BaseLayout>
                            )
                        case 3:
                            return (
                                <BaseLayout key={start} box={'larger-box'} showForwardButton={true} forwardButtonHandler={() => setStart(4)}>
                                    <Ranking />
                                </BaseLayout>
                            )
                        case 4:
                            return (
                                <BaseLayout key={start} showBackButton={dataApplication.apps.length > 1 || appState.reset?.status ? true : false} backButtonHandler={() => handleExit()}>
                                    <End />
                                </BaseLayout>
                            )
                        default:
                            return ("")
                    }
                })()}
            </AnimatePresence>

            {definitions.players === 2 ? <div className="memoryCardPlayers">
                <AnimatePresence initial={true} mode="wait">
                    {definitions.players === 2 && <PlayerCard key={currentPlayer} currentPlayer={currentPlayer} score={score}></PlayerCard>}
                </AnimatePresence>
            </div> : ''}
        </>
    )
}

export default MemoryGame
