import React, { useReducer, useContext, useState, useEffect } from 'react';
import { io } from 'socket.io-client';
import HelmetStyle from '../components/HelmetStyle.js'
import { useGlobalState } from '../../../hooks/useCustomization.js';
import { domainConfig } from "../../../assets/config.js"
import NotyfContext from "../../../contexts/NotyfContext.js";
import '../assets/style.scss'
import 'swiper/css';
import 'swiper/css/zoom';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

let socket

const AppContext = React.createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case 'CONNECTED':
      return {
        ...state,
        isConnected: action.payload,
      }
    case 'setParticipants':
      return {
        ...state,
        participants: action.payload,
      }
    case 'setUserHistory':
      return {
        ...state,
        userHistory: action.payload,
      }
    case 'setContexto':
      return {
        ...state,
        context: action.payload,
      }
    case 'deleteVisitante':
      const updatedParticipants = state.participants.filter(visitante => visitante.visitante_id !== action.payload);
      return {
        ...state,
        participants: updatedParticipants,
      };
    case 'camera':
      return {
        ...state,
        camera: {
          ...state.camera,
          [action.payload.type]: action.payload.value
        }
      }
    default:
      return state;
  }
};

const GameProvider = ({ children }) => {
  const { dataApplication, setStyleVersion } = useGlobalState();
  const [appState, dispatch] = useReducer(reducer, dataApplication.data);
  const [updateData, setUpdateData] = useState(0)
  const [showPresentation, setShowPresentation] = useState(false)
  const [formValues, setFormValues] = useState()
  const [showPresentationGallery, setShowPresentationGallery] = useState(false)
  const [mediaPreview, setMediaPreview] = useState(false)
  const [lastUser, setLastUser] = useState(false)
  const [showQRCode, setShowQRCode] = useState(false)
  const [drawResult, setDrawResult] = useState()
  const [isPlaying, setIsPlaying] = useState(true)
  const [image, setImage] = useState(false);
  const [video, setVideo] = useState(false);
  const [props, setProps] = useState({ 'media': null, 'id': null });
  const notyf = useContext(NotyfContext);

  useEffect(() => {
    if (dataApplication && dataApplication.presenter) {
      socket = io(domainConfig.socketAPI + '?aplicativo_id=' + dataApplication.data.aplicativo_id + '&customizacao_id=' + dataApplication.data.customizacao_id, {
        extraHeaders: {
          'x-event-id': dataApplication.evento_id, // Include event_id in headers
        },
        autoConnect: true,
        withCredentials: true
      });
      socket.on('connect', () => {
        console.log('connect')
        dispatch({ type: 'CONNECTED', payload: true });
      });
      socket.on('login', (result) => {
        dispatch({ type: 'setContexto', payload: result });
      });
      socket.on('disconnect', () => {
        console.log('disconnect')
        dispatch({ type: 'CONNECTED', payload: false });
      });
      socket.on('updateData', () => {
        console.log('updateData')
        setUpdateData((prevState) => prevState + 1)
      });
      socket.on('getData', (result) => {
        let data = processParticipants(result.participants, result.results)
        dispatch({ type: 'setParticipants', payload: data.participants });
      });
      socket.on('createImageAI', (result) => {
        console.log(result)
        setProps(prevProps => ({
          ...prevProps,
          media: result
        }));
      });
      socket.on('updateCustomization', (data) => {
        setStyleVersion(prevValue => prevValue + 1)
      });
      socket.on('postResult', (data) => {
        console.log(data)
        setProps(prevProps => ({
          ...prevProps,
          id: data
        }));
      })
      socket.on('deleteVisitante', (data) => {
        if (data.success) {
          dispatch({ type: 'deleteVisitante', payload: data.visitante_id });
        } else {
          notyf.open({
            type: "danger",
            message: 'Houve um erro ao remover o visitante, tente novamente',
            ripple: true,
            dismissible: true,
          });
        }
      });
      socket.emit('login');
      return () => {
        if (socket) {
          socket.disconnect();
          console.log('Socket disconnected');
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppContext.Provider value={{
      appState, dispatch, showPresentation, updateData, setUpdateData, showQRCode, setShowQRCode,
      setShowPresentation, drawResult, setDrawResult, isPlaying, setIsPlaying, image, setImage, video,
      setVideo, props, setProps, showPresentationGallery, setShowPresentationGallery, formValues,
      setFormValues, mediaPreview, setMediaPreview, lastUser, setLastUser
    }}>
      <HelmetStyle appState={appState} />
      {children}
    </AppContext.Provider>
  );
};

const getData = (type) => {
  socket.emit('getData', type);
};

const updateData = () => {
  socket.emit('updateData');
};

const postResult = (props, visitante_id) => {
  socket.emit('postResult', props, visitante_id);
};

const updateCustomization = (data) => {
  socket.emit('updateCustomization', data);
};

const deleteVisitante = (props) => {
  socket.emit('deleteVisitante', props);
};

export {
  AppContext,
  GameProvider,
  getData,
  updateData,
  updateCustomization,
  postResult,
  deleteVisitante
};

export const useAppState = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppState must be used within a GlobalStateProvider');
  }
  return context;
};

function processParticipants(participants, results) {
  let newHistory = JSON.parse(JSON.stringify(results))

  newHistory = newHistory.map(historyItem => {
    let parsedItem = JSON.parse(historyItem.json);
    parsedItem.data = historyItem.data;
    parsedItem.visitante_id = historyItem.visitante_id;
    return parsedItem;
  })

  participants.forEach(participant => {
    participant.participantDetails = JSON.parse(participant.json);
    const transformedDetails = {};

    for (const key in participant.participantDetails) {
      const detail = participant.participantDetails[key];
      transformedDetails[detail.label] = detail.value;
    }
    Object.assign(participant, transformedDetails);

    newHistory.forEach(historyItem => {
      if (historyItem.visitante_id === participant.visitante_id) {
        participant.history = historyItem;
        participant.media = historyItem.media;
        Object.keys(participant.participantDetails).forEach(key => {
          let element = participant.participantDetails[key]
          let newElements = { [element.label]: element.value }
          Object.assign(historyItem, newElements);
        });
      }
    });
  });

  return { 'participants': participants, 'results': newHistory };
}
