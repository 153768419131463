import React from 'react'
import { AnimatePresence } from "framer-motion"
import End from "./components/End.js"
import { useGlobalState } from '../../hooks/useCustomization.js'
import Result from "./components/Result.js"
import Ranking from "./components/Ranking.js"
import Chart from "./components/Chart.js"
import AdditionalAnswer from "./components/AdditionalAnswer.js"
import Exercises from "./components/Exercises.js"
import { useAppState } from './context/AppContext.js'
import StartAnimation from "./components/StartAnimation.js"
import BaseLayout from "../../components/BaseLayout"

const Quiz = () => {
    const { dataApplication, handleExit } = useGlobalState();
    const { appState, start, handleNextStep } = useAppState();

    return (
        <AnimatePresence mode={'wait'}>
            {(() => {
                switch (start) {
                    case 0:
                        return (
                            <StartAnimation key={start} timer={5} value={1} />
                        )
                    case 1:
                        return (
                            <BaseLayout key={start} box={'full-box'} showBackButton={dataApplication.apps.length > 1 || appState.reset?.status ? true : false} backButtonHandler={() => handleExit()}>
                                <Exercises />
                            </BaseLayout>
                        )
                    case 2:
                        return (
                            <BaseLayout key={start} box={'larger-box'} showForwardButton={true} forwardButtonHandler={handleNextStep}>
                                <Result />
                            </BaseLayout>
                        )
                    case 3:
                        return (
                            <BaseLayout key={start} box={'larger-box'} showForwardButton={true} forwardButtonHandler={handleNextStep}>
                                <Ranking />
                            </BaseLayout>
                        )
                    case 4:
                        return (
                            <BaseLayout key={start} box={'larger-box'} showForwardButton={true} forwardButtonHandler={handleNextStep}>
                                <Chart />
                            </BaseLayout>
                        )
                    case 5:
                        return (
                            <BaseLayout key={start} box={'larger-box'} showForwardButton={true} forwardButtonHandler={handleNextStep}>
                                <AdditionalAnswer />
                            </BaseLayout>
                        )
                    case 6:
                        return (
                            <BaseLayout key={start} showBackButton={dataApplication.apps.length > 1 || appState.reset?.status ? true : false} backButtonHandler={() => handleExit()}>
                                <End />
                            </BaseLayout>
                        )
                    default:
                        return ("")
                }
            })()}
        </AnimatePresence>
    )
}

export default Quiz
