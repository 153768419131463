

import React, { useEffect, useContext, useRef, useMemo, useState } from 'react'
import axios from 'axios'
import * as Yup from "yup";
import { AnimatePresence, motion } from "framer-motion"
import {
    Col,
    Form,
    Row,
    OverlayTrigger,
    Tooltip,
    Pagination
} from "react-bootstrap"
import { Formik } from "formik"
import { formatInTimeZone } from "date-fns-tz";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSquareXmark,
    faMessage,
    faThumbsUp,
    faThumbsDown,
    faSquareCheck
} from "@fortawesome/free-solid-svg-icons";
import { useAppState } from './context/AppContext.js'
import { PageLoaderDots, PageLoader } from '../../components/Elements.js'
import NotyfContext from "../../contexts/NotyfContext.js";
import { TextWithLineBreaks, getRandomInterval, convertDate } from "../../components/Functions.js"
import { useGlobalState } from '../../hooks/useCustomization';
import BaseLayout from '../../components/BaseLayout'

const MessageBoard = () => {
    const { dataApplication, handleExit } = useGlobalState();
    const { appState, dispatch, showMessages, showBoard } = useAppState();
    const [firstRender, setFirstRender] = useState(true);

    const getData = (setMessages = false) => {
        axios.get(`/api/eventos/messagesDisplay?evento_id=${dataApplication.evento_id}&customizacao_id=${appState.customizacao_id}&visitante_id=${dataApplication.visitante.visitante_id}&messageBoard=1&type=${appState.type}`)
            .then(function (response) {
                console.log(response)
                dispatch({
                    type: 'setMessages', payload: response.data.message
                });
                dispatch({
                    type: 'setBoardMessages', payload: response.data.boardMessages
                });
                if (setMessages) {
                    if (showBoard) {
                        setMessages(response.data.boardMessages)
                    } else {
                        setMessages(response.data.message)
                    }
                }
            }).catch(function (error) {
                console.log(error)
            })
    }

    return (
        <BaseLayout showBackButton={dataApplication.apps.length > 1 ? true : false} backButtonHandler={() => handleExit()}>
            <AnimatePresence mode="wait">
                {showMessages ? (
                    <MessageSent key={'messageSent'} getData={getData}></MessageSent>
                ) : showBoard ? ( // Conditionally render MessagePreview
                    <ShowMessagesBoard key={'ShowMessagesBoard'} getData={getData}></ShowMessagesBoard>
                ) : (
                    <MessageForm key={'MessageForm'} getData={getData} firstRender={firstRender} setFirstRender={setFirstRender}></MessageForm>
                )}
            </AnimatePresence>
        </BaseLayout >
    )
}

const MessageSent = ({ getData }) => {
    const { dataApplication } = useGlobalState();
    const { appState, handleSentMessage, updateMessages } = useAppState();
    const [messages, setMessages] = useState(undefined)

    function removeMessageDisplay(mensagem_id) {
        axios.delete(`/api/eventos/messagesDisplay/${mensagem_id}`)
            .then(function (response) {
                setMessages(prevItems => prevItems.filter(item => item.mensagem_id !== mensagem_id));
            }).catch(function (error) {
                console.log(error)
            })
    }

    const ItemList = ({ index }) => {
        let text = messages[index].message
        const dateX = new Date(messages[index].data);
        const date = formatInTimeZone(dateX, "America/Sao_Paulo", " dd/MM HH:mm");
        return (
            <li className="list-group-item" key={'listGroup' + index}>
                <div className="row">
                    <div className="col">
                        <div className="horizontally-centered">
                            <span className="me-3 fs-12">
                                {date}
                            </span>
                            <div>
                                {appState.userFeedback && messages[index].lido === 1 &&
                                    <OverlayTrigger
                                        placement={"bottom"}
                                        overlay={<Tooltip>Lido</Tooltip>}
                                    >
                                        <FontAwesomeIcon className="vertically-centered px-1 primaryColorT" size={"lg"} icon={faSquareCheck} />
                                    </OverlayTrigger>
                                }
                                {appState.userFeedback && messages[index].aprovado > 0 &&
                                    <OverlayTrigger
                                        placement={"bottom"}
                                        overlay={<Tooltip>{messages[index].aprovado === 1 ? 'Aprovado' : 'Reprovado'}</Tooltip>}
                                    >
                                        <FontAwesomeIcon className="vertically-centered px-1 primaryColorT" size={"lg"} icon={messages[index].aprovado === 1 ? faThumbsUp : faThumbsDown} />
                                    </OverlayTrigger>
                                }
                                {appState.deleteHistory &&
                                    <OverlayTrigger
                                        placement={"bottom"}
                                        overlay={<Tooltip>Excluir</Tooltip>}
                                    >
                                        <FontAwesomeIcon className="vertically-centered cursor-pointer ps-1 primaryColorT" onClick={() => removeMessageDisplay(messages[index].mensagem_id)} size={"lg"} icon={faSquareXmark} />
                                    </OverlayTrigger>
                                }
                            </div>
                        </div>
                        <p className="mb-0">Mensagem: <span className="opacity-75">{text}</span></p>
                    </div>
                </div>
            </li>
        )
    }

    useEffect(() => {
        setTimeout(() => {
            getData(setMessages)
        }, 500);
        const intervalId = setInterval(() => {
            getData(setMessages)
        }, getRandomInterval(10000, 15000));
        return () => clearInterval(intervalId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateMessages])

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <h5 className="mb-3">Histórico</h5>
            <ul className="list-group text-start mb-3 pt-4">
                {!messages ? (
                    <div className="text-center">
                        <PageLoader color={dataApplication.customizacao.primaryColor} width={50}></PageLoader>
                    </div>
                ) : (
                    <>
                        {messages && messages.length > 0 ? (
                            <div>
                                {messages && Object.keys(messages).map(key => (
                                    <ItemList key={'itemList' + key} index={key}></ItemList>
                                ))}
                            </div>
                        ) : (
                            <li className="list-group-item py-4">
                                <div className="row">
                                    <div className="col">
                                        <p className="text-center small my-2">Não foi encontrado nenhum resultado</p>
                                    </div>
                                </div>
                            </li>
                        )}
                    </>
                )}
            </ul>
            <div className="text-center">
                <button className="btn primaryColor primaryColorText mt-3" type="button" onClick={() => handleSentMessage()}>Voltar</button>
            </div>
        </motion.div>
    )
}

const ShowMessagesBoard = ({ getData }) => {
    const { dataApplication } = useGlobalState();
    const { appState, dispatch, setUpdateMessages, handleSentMessage, updateMessages } = useAppState();
    const [messages, setMessages] = useState(undefined)
    const [pageIndex, setPageIndex] = useState(0);

    const [pageSize, setPageSize] = useState(10);

    const pageCount = useMemo(() => {
        return messages && messages.length ? Math.ceil(messages.length / pageSize) : 0;
    }, [messages, pageSize]);


    const currentPageMessages = useMemo(() => {
        const start = pageIndex * pageSize;
        return messages && messages.length ? messages.slice(start, start + pageSize) : [];
    }, [pageIndex, pageSize, messages]);

    const canPreviousPage = pageIndex > 0;
    const canNextPage = pageIndex < pageCount - 1;

    // Pagination handlers
    const gotoPage = (page) => setPageIndex(page);
    const previousPage = () => setPageIndex((old) => Math.max(old - 1, 0));
    const nextPage = () => setPageIndex((old) => Math.min(old + 1, pageCount - 1));

    useEffect(() => {
        setTimeout(() => {
            getData(setMessages)
        }, 500);
        const intervalId = setInterval(() => {
            getData(setMessages)
        }, getRandomInterval(10000, 15000));
        return () => clearInterval(intervalId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateMessages])

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <h5 className="mb-3">Quadro de mensagens</h5>
            <div className="col">
                <button className="btn btn-sm primaryColor primaryColorText mt-4 " onClick={() => handleSentMessage(true)}>
                    <span className='cursor-pointer'>
                        Meu histórico
                        ({appState.messages && appState.messages.length ? appState.messages.length : 0})
                    </span>
                </button>
            </div>
            <ul className="list-group text-start mb-4 pt-4">
                {!messages ? (
                    <div className="text-center">
                        <PageLoader color={dataApplication.customizacao.primaryColor} width={50}></PageLoader>
                    </div>
                ) : (
                    <>
                        {currentPageMessages && currentPageMessages.length > 0 ? (
                            <AnimatePresence mode="wait">
                                <motion.div className="row" key={'page' + pageIndex} initial={{ opacity: 0 }}
                                    animate={{ opacity: 1, transition: { duration: 0.25 } }}
                                    exit={{ opacity: 0 }}>
                                    <AnimatePresence mode="popLayout">
                                        {currentPageMessages && Object.keys(currentPageMessages).map(key => (
                                            <ItemListBoard messages={currentPageMessages} key={'itemList' + currentPageMessages[key].mensagem_id} index={key}></ItemListBoard>
                                        ))}
                                    </AnimatePresence>
                                    <Row className="text-start mx-0">
                                        <Col md="6">
                                            <span className="mx-2">
                                                Página{" "}
                                                <span>
                                                    {pageIndex + 1} de {pageCount}
                                                </span>
                                            </span>
                                            <span className="ms-3 me-2">Mostrar:</span>
                                            <Form.Select
                                                className="d-inline-block w-auto"
                                                value={pageSize}
                                                onChange={(e) => {
                                                    setPageSize(Number(e.target.value));
                                                }}
                                            >
                                                {[10, 20, 30, 40, 50].map((pageSize) => (
                                                    <option key={pageSize} value={pageSize}>
                                                        {pageSize}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </Col>
                                        <Col md="6">
                                            <Pagination className="float-end mt-sm-0 mt-2">
                                                <Pagination.First
                                                    onClick={() => gotoPage(0)}
                                                    disabled={!canPreviousPage}
                                                />
                                                <Pagination.Prev
                                                    onClick={() => previousPage()}
                                                    disabled={!canPreviousPage}
                                                />
                                                <Pagination.Next
                                                    onClick={() => nextPage()}
                                                    disabled={!canNextPage}
                                                />
                                                <Pagination.Last
                                                    onClick={() => gotoPage(pageCount - 1)}
                                                    disabled={!canNextPage}
                                                />
                                            </Pagination>
                                        </Col>
                                    </Row>
                                </motion.div>
                            </AnimatePresence>

                        ) : (
                            <li className="list-group-item py-4">
                                <div className="row">
                                    <div className="col">
                                        <p className="text-center small my-2">Não foi encontrado nenhum resultado</p>
                                    </div>
                                </div>
                            </li>
                        )}
                    </>
                )}
            </ul>
            <div className="text-center">
                <button className="btn primaryColor primaryColorText mt-3" type="button" onClick={() => handleSentMessage()}>Voltar</button>
            </div>
        </motion.div>
    )
}

const ItemListBoard = ({ messages, index }) => {
    const { dataApplication } = useGlobalState();
    console.log(messages[index])
    return (
        <motion.div key={'cardContainer' + messages[index].mensagem_id} className="col-12 mb-3"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0, transition: { duration: 0.25 } }}
            transition={{ duration: 0.5 }}>
            <div className="cardContainer">
                <div className="cardMessage">
                    <div className="cardTypeDate">{convertDate(messages[index].data)}</div>
                    <p className="text-start mt-4">
                        De: {dataApplication.customizacao.form.fieldsToShow && dataApplication.customizacao.form.fieldsToShow.map((inputID, index) => {
                            const field = dataApplication.customizacao.form.fields.find(field => field.inputID === inputID);
                            if (field && messages[index]?.participantDetails && messages[index]?.participantDetails[field.inputID]) {
                                const isLast = index === dataApplication.customizacao.form.fieldsToShow.length - 1;
                                return (
                                    <span className={`badge primaryColor primaryColorText fw-normal mb-2 ${isLast ? '' : 'me-2'}`} key={'field' + index}>
                                        {messages[index].participantDetails[field.inputID].value}
                                    </span>
                                );
                            }
                            return null; // Handle cases where the field is not found (optional)
                        })}
                        <br></br>
                        Para: <span className={`badge primaryColor primaryColorText fw-normal mb-2`}>
                            {messages[index].json?.recipient}
                        </span>
                    </p>
                    <p className="pt-2">{messages[index].message}</p>
                </div>
            </div>
        </motion.div>
    )
}

const MessageForm = ({ getData, firstRender, setFirstRender }) => {
    const { dataApplication } = useGlobalState();
    const { appState, handleSentMessage, setUpdateMessages, updateMessages } = useAppState();
    const formikRef = useRef(null);
    const notyf = useContext(NotyfContext);

    const submitFormExternally = () => {
        if (formikRef.current) {
            formikRef.current.submitForm();
        }
    };

    function registerMessage(values, actions) {
        const dateX = new Date();
        const now = formatInTimeZone(dateX, "UTC", "yyyy-MM-dd HH:mm:ss");
        let json = {}
        if (values.recipient) {
            json = { recipient: values.recipient }
        }
        axios.post("/api/eventos/messagesDisplay", {
            evento_id: dataApplication.evento_id,
            customizacao_id: appState.customizacao_id,
            visitante_id: dataApplication.visitante.visitante_id,
            message: values.message,
            data: now,
            lido: 0,
            aprovado: appState.autoApproval ? 1 : 0,
            favorito: 0,
            data_lido: null,
            json: JSON.stringify(json)
        }).then(function (response) {
            notyf.open({
                type: "success",
                message: 'Enviado com sucesso!',
                ripple: true,
                dismissible: true,
            });
            if (appState.showMessageHistory) {
                handleSentMessage()
            } else {
                actions.resetForm();
                actions.setSubmitting(false);
                setUpdateMessages((prevState) => prevState + 1)
            }
        }).catch(function (error) {
            actions.resetForm();
            actions.setSubmitting(false);
            notyf.open({
                type: "danger",
                message: 'Houve um erro, tente novamente',
                ripple: true,
                dismissible: true,
            });
        })
    }

    useEffect(() => {
        if (firstRender) {
            setFirstRender(false);
        } else {
            getData()
        }
        const intervalId = setInterval(() => {
            getData()
        }, getRandomInterval(10000, 15000));
        return () => clearInterval(intervalId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateMessages])
    console.log(appState)
    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <div className="col">
                {appState.title.status && <div className="col"><h5 className="mb-3"><TextWithLineBreaks text={appState.title.value}></TextWithLineBreaks></h5></div>}
                {appState.description.status && <div className="col"><p><TextWithLineBreaks text={appState.description.value}></TextWithLineBreaks></p></div>}
                <button className="btn btn-sm primaryColor primaryColorText mt-4 buttonWithIcon" onClick={() => handleSentMessage()}>
                    <span className={`${appState.showMessageHistory ? ('cursor-pointer') : ('')}`}>
                        <FontAwesomeIcon className={`vertically-centered me-2`} icon={faMessage} />
                        {appState.type === 2 ? appState.boardMessages && appState.boardMessages.length ? appState.boardMessages.length : 0 : appState.messages && appState.messages.length ? appState.messages.length : 0}
                    </span>
                </button>
            </div>
            {appState.messages && appState.messages.length >= appState.messageLimit ? (
                <>
                    <span className="badge primaryColor primaryColorText mt-4 fw-normal">Você atingiu o limite de envios</span>
                </>
            )
                : (
                    <Formik
                        validationSchema={Yup.object().shape({
                            message: Yup.string()
                                .required("Campo obrigatório")
                                .max(appState.maxlength, `Máximo de ${appState.maxlength} caracteres`)
                                .min(appState.minlength, `Mínimo de ${appState.minlength} caracteres`),
                            recipient: Yup.string().test(
                                'conditional-required',
                                'Campo obrigatório',
                                function (value) {
                                    if (appState.type === 2) {
                                        if (value !== '') {
                                            return true
                                        } else {
                                            return false
                                        }
                                    } else {
                                        return true
                                    }
                                }
                            )
                                .max(50, `Máximo de 50 caracteres`)
                                .min(3, `Mínimo de 3 caracteres`),
                        })}
                        onSubmit={registerMessage}
                        validateOnChange={false}
                        validateOnBlur={true}
                        enableReinitialize
                        initialValues={{
                            message: '',
                            recipient: ''
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            isValid,
                            errors,
                            dirty,
                            isSubmitting,
                            setSubmitting,
                            setFieldValue,
                        }) => (
                            <Form noValidate onSubmit={handleSubmit} className="text-start">
                                <Row className="mx-0">
                                    {appState.type === 2 &&
                                        <>
                                            <Form.Group as={Col} sm={12} className='my-3'>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Nome do recipiente"
                                                    name={'recipient'}
                                                    value={values.recipient}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.recipient}
                                                    isValid={touched.recipient && !errors.recipient}
                                                />
                                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.recipient}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </>}
                                    <Form.Group as={Col} sm={12} className='my-3'>
                                        <Form.Control
                                            style={{ height: "100px" }}
                                            type="text"
                                            as="textarea"
                                            placeholder="Digite seu texto aqui"
                                            name={'message'}
                                            value={values.message}
                                            onChange={handleChange}
                                            isInvalid={!!errors.message}
                                            isValid={touched.message && !errors.message}
                                        />
                                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <div className="text-center mt-3">
                                    <button className="btn primaryColor btn-lg primaryColorText" type="submit" disabled={isSubmitting} onClick={submitFormExternally}>
                                        {!isSubmitting ? 'Enviar' : <PageLoaderDots color={dataApplication.customizacao.primaryColorText} width="25" padding={0} />}
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                )}
        </motion.div>
    )
}

export default MessageBoard