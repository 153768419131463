

import React, { useState, useEffect } from 'react'
import { AnimatePresence, motion } from "framer-motion"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import QRCode from "react-qr-code";
import { formatInTimeZone } from "date-fns-tz";
import {
    faCircleXmark,
    faGift
} from "@fortawesome/free-solid-svg-icons";
import axios from 'axios'
import { useAppState } from './context/AppContext.js'
import { TextWithLineBreaks, getRandomInterval } from "../../components/Functions.js"
import { PageLoader, PageLoaderDots } from '../../components/Elements.js'
import { useGlobalState } from '../../hooks/useCustomization';
import { domainConfig } from "../../assets/config"
import BaseLayout from "../../components/BaseLayout.js"
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const PrizeDraw = () => {
    const { dataApplication, handleExit } = useGlobalState();
    const { appState, updateData, setDrawResult, drawPage, setDrawPage, dispatch } = useAppState();

    const getData = () => {
        let url = `/api/eventos/prizeDrawUser?evento_id=${dataApplication.evento_id}&customizacao_id=${appState.customizacao_id}&visitante_id=${dataApplication.visitante?.visitante_id}&drawMode=${appState.drawMode}&maxDraw=${appState.maxDraws}`
        axios.get(url)
            .then(function (response) {
                if (appState.drawMode === 2) {
                    if (response.data.message.currentPrize) {
                        dispatch({
                            type: 'setCurrentPrize', payload: response.data.message.currentPrize
                        });
                        setDrawPage('draw')
                    } else {
                        setDrawPage('history')
                    }
                } else {
                    let isVisitorIdPresent = false
                    if (response.data.message.context.data?.type === "DrawDefault") {
                        response.data.message.context.draw.result.forEach(item => {
                            if (item.visitante_id === dataApplication.visitante.visitante_id) {
                                isVisitorIdPresent = true;
                            }
                        });
                        if (isVisitorIdPresent) {
                            setDrawResult('winner')
                        } else {
                            setDrawResult('loser')
                        }
                    } else {
                        setDrawResult('waiting')
                    }
                    if (!drawPage) {
                        setDrawPage('draw')
                    }
                }
                dispatch({
                    type: 'setUserHistory', payload: response.data.message.history
                });
                dispatch({
                    type: 'setContexto', payload: response.data.message.context
                });
                dispatch({
                    type: 'setValidado', payload: response.data.message.validado
                });
            }).catch(function (error) {
                console.log(error)
            })
    }

    useEffect(() => {
        getData()
        if (appState.drawMode === 1) {
            const intervalId = setInterval(() => {
                getData()
            }, getRandomInterval(10000, 15000));
            return () => clearInterval(intervalId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateData, drawPage])

    return (
        <BaseLayout showBackButton={dataApplication.apps.length > 1 ? true : false} backButtonHandler={() => handleExit()}>
            <AnimatePresence mode="wait" initial={true}>
                {appState.userHistory && drawPage ? (
                    <motion.div key={'result'} initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { duration: 0.25 } }}
                        exit={{ opacity: 0 }}>
                        {appState.prizeDrawType === 1 ? (
                            <WithoutQRCode key={'PrizeResult'}></WithoutQRCode>
                        ) : (
                            <WithQRCode key={'QRCodeResult'}></WithQRCode>
                        )}
                    </motion.div>
                ) : (
                    <motion.div key={'loading'} className="vertically-centered py-5" initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { duration: 0.25 } }}
                        exit={{ opacity: 0 }}>
                        <PageLoader color={dataApplication.customizacao.primaryColor} width={75}></PageLoader>
                    </motion.div>
                )}
            </AnimatePresence>
        </BaseLayout>
    )
}

const HandleDraw = () => {
    const { drawPage } = useAppState();

    return (
        <AnimatePresence mode={'wait'}>
            {drawPage === 'draw' ? <ResultDraw key={'DrawResult'} ></ResultDraw> : <ResultDrawHistory key={'DrawHistory'} ></ResultDrawHistory>}
        </AnimatePresence>
    )
}

const ResultDraw = () => {
    const { appState, setDrawPage } = useAppState();
    const [result, setResult] = useState(false)

    return (
        <motion.div initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.75 } }}
            exit={{ opacity: 0 }}>
            <AnimatePresence mode={'wait'}>
                {result === false ? (
                    <ResultDrawGif setResult={setResult}></ResultDrawGif>
                ) : (
                    <motion.div key={'result02'} className="mb-3" initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { duration: 0.75 } }}
                        exit={{ opacity: 0 }}>
                        {result.prize === null ? (
                            <>
                                <img className="drawPrizeGif" src={domainConfig.imageServer + "/customizacao/" + appState.customizacao_id + "/" + appState.showResult.loserImage} alt="loserImage"></img>
                                <p className="mt-3"><TextWithLineBreaks text={appState.showResult.loserText}></TextWithLineBreaks></p>
                            </>
                        ) : (
                            <>
                                <img className="drawPrizeGif" src={domainConfig.imageServer + "/customizacao/" + appState.customizacao_id + "/" + appState.showResult.winnerImage} alt="winnerImage"></img>
                                <p className="mt-3"><TextWithLineBreaks text={appState.showResult.winnerText}></TextWithLineBreaks></p>
                                <div className="card">
                                    <div className="card-body">
                                        {result.prize === null ? 'Nehum prêmio ganho' : result.prize}
                                    </div>
                                </div>
                            </>
                        )}
                        <div className="text-center mt-3">
                            <button className="btn primaryColor primaryColorText mt-3" type="button" onClick={() => setDrawPage('history')}>Continuar</button>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </motion.div>
    )
}

const ResultDrawGif = ({ setResult }) => {
    const { dataApplication } = useGlobalState();
    const { appState, dispatch, updateData } = useAppState();

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            let history = JSON.parse(JSON.stringify(appState.userHistory));
            history.push(appState.currentPrize);
            dispatch({
                type: 'setUserHistory', payload: history
            });
            axios.post(`/api/eventos/history/`, {
                evento_id: dataApplication.evento_id,
                customizacao_id: appState.customizacao_id,
                json: JSON.stringify(history)
            }).then(function (response) {
                console.log(response)
            }).catch(function (error) {
                console.log(error);
            });
            setResult(appState.currentPrize)
        }, appState.showResult.drawImageTimeout);

        return () => clearTimeout(timeoutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <motion.div key={'ResultDrawGif' + updateData} initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.75 } }}
            exit={{ opacity: 0 }}>
            <img className="drawPrizeGif" src={domainConfig.imageServer + "/customizacao/" + appState.customizacao_id + "/" + appState.showResult.drawImage} alt="drawImage"></img>
        </motion.div>
    )
}

const ResultDrawHistory = () => {
    const { dataApplication } = useGlobalState();
    const { appState, setUpdateData } = useAppState();
    const [loading, setLoading] = useState(false)

    function startNewDraw() {
        if (appState.userHistory.length < appState.maxDraws) {
            setUpdateData((prevState) => prevState + 1)
            setLoading(true)
        }
    }

    const ItemList = ({ props }) => {
        let dateX = new Date(props.data);
        dateX = formatInTimeZone(dateX, "America/Sao_Paulo", " dd/MM HH:mm");

        return (
            <li className="list-group-item" key={'listGroup'}>
                <div className="row">
                    <div className="col">
                        <div className="horizontally-centered">
                            <span className="me-2 fs-12">
                                {dateX}
                            </span>
                        </div>
                        <p className="mb-0">Prêmio: <span className="opacity-75">{props.prize === null ? 'Nehum prêmio ganho' : props.prize}</span></p>
                    </div>
                </div>
            </li>
        )
    }

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <div className="col"><h5 className="mb-3">Histórico</h5></div>
            <div className="col">
                {appState.userHistory.length < appState.maxDraws ? (
                    <p>Vocxê ainda tem ({Number(appState.maxDraws) - Number(appState.userHistory.length)}) tentativas disponíveis</p>
                ) : (
                    <p>Você não possui mais tentativas</p>
                )
                }
            </div>
            <ul className="list-group text-start mb-4">
                {!appState.userHistory || Object.keys(appState.userHistory).length === 0 ? (
                    <div>
                        <PageLoader color={dataApplication.customizacao.primaryColor} width={50}></PageLoader>
                    </div>
                ) : (
                    <>
                        {appState.userHistory && appState.userHistory.length > 0 ? (
                            <div>
                                {appState.userHistory && Object.keys(appState.userHistory).map(key => (
                                    <ItemList key={'itemList' + key} props={appState.userHistory[key]}></ItemList>
                                ))}
                            </div>
                        ) : (
                            <li className="list-group-item py-4">
                                <div className="row">
                                    <div className="col">
                                        <p className="text-center small my-2">Não foi encontrado nenhum resultado</p>
                                    </div>
                                </div>
                            </li>
                        )}
                    </>
                )}
            </ul>
            <div className="text-center">
                <button className="btn primaryColor primaryColorText mt-3" type="button" disabled={appState.userHistory.length >= appState.maxDraws || loading === true ? true : false} onClick={() => startNewDraw()}>
                    {!loading ?
                        <>
                            <FontAwesomeIcon icon={faGift} size={'lg'} className="me-1" />
                            Sortear ({Number(appState.maxDraws) - Number(appState.userHistory.length)})
                        </> :
                        <PageLoaderDots color={dataApplication.customizacao.primaryColor} width="25" padding={0} />}
                </button>
            </div>
        </motion.div>
    )
}

const WithoutQRCode = () => {
    const { appState } = useAppState();


    return (
        <AnimatePresence mode={'wait'}>
            {appState.drawMode === 2 ? (
                <HandleDraw></HandleDraw>
            ) : (
                <HandlePrize></HandlePrize>
            )}
        </AnimatePresence>
    )
}

const HandlePrize = () => {
    const { drawPage } = useAppState();

    return (
        <AnimatePresence mode={'wait'}>
            {drawPage === 'draw' ?
                <motion.div initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transition: { duration: 0.75 } }}
                    exit={{ opacity: 0 }}>
                    <AnimatePresence mode={'wait'}>
                        <PrizeResult></PrizeResult>
                    </AnimatePresence>
                </motion.div>
                : <PrizeResultHistory key={'PrizeResultHistory'} ></PrizeResultHistory>}
        </AnimatePresence>
    )
}

const PrizeResult = () => {
    const { appState, drawResult, setDrawPage } = useAppState();

    switch (drawResult) {
        case 'winner':
            return (
                <motion.div key={'winner'} initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transition: { duration: 0.75 } }}
                    exit={{ opacity: 0 }}>
                    <img className="drawPrizeGif" src={domainConfig.imageServer + "/customizacao/" + appState.customizacao_id + "/" + appState.showResult.winnerImage} alt="winnerImage"></img>
                    <p className="mt-3"><TextWithLineBreaks text={appState.showResult.winnerText}></TextWithLineBreaks></p>
                    <div className="text-center mt-4">
                        <button className="btn primaryColor primaryColorText mt-3" type="button" onClick={() => setDrawPage('history')}><FontAwesomeIcon icon={faGift} size={'lg'} className="me-1" /> Meus prêmios ({appState.userHistory.length})</button>
                    </div>
                </motion.div>
            )
        case 'loser':
            return (
                <motion.div key={'loser'} initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transition: { duration: 0.75 } }}
                    exit={{ opacity: 0 }}>
                    <img className="drawPrizeGif" src={domainConfig.imageServer + "/customizacao/" + appState.customizacao_id + "/" + appState.showResult.loserImage} alt="loserImage"></img>
                    <p className="mt-3"><TextWithLineBreaks text={appState.showResult.loserText}></TextWithLineBreaks></p>
                    <div className="text-center mt-4">
                        <button className="btn primaryColor primaryColorText mt-3" type="button" onClick={() => setDrawPage('history')}><FontAwesomeIcon icon={faGift} size={'lg'} className="me-1" /> Meus prêmios ({appState.userHistory.length})</button>
                    </div>
                </motion.div>
            )
        case 'waiting':
            return (
                <motion.div key={'waiting'} initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 0.75 } }} exit={{ opacity: 0 }}>
                    <div>
                        {appState.title.status && <div className="col"><h5 className="mb-3"><TextWithLineBreaks text={appState.title.value}></TextWithLineBreaks></h5></div>}
                        {appState.description.status && <div className="col"><p><TextWithLineBreaks text={appState.description.value}></TextWithLineBreaks></p></div>}
                    </div>
                    <div>
                        <div>
                            <img className="drawPrizeGif" src={domainConfig.imageServer + "/customizacao/" + appState.customizacao_id + "/" + appState.showResult.waitingImage} alt="waitingImage"></img>
                            <p><TextWithLineBreaks text={appState.showResult.waitingText}></TextWithLineBreaks></p>
                        </div>
                    </div>
                    <div className="text-center mt-4">
                        <button className="btn primaryColor primaryColorText mt-3" type="button" onClick={() => setDrawPage('history')}><FontAwesomeIcon icon={faGift} size={'lg'} className="me-1" /> Meus prêmios ({appState.userHistory.length})</button>
                    </div>
                </motion.div>
            )
        default:
            return ""
    }
}

const PrizeResultHistory = () => {
    const { dataApplication } = useGlobalState();
    const { appState, setDrawPage } = useAppState();

    const ItemList = ({ props }) => {
        let dateX = new Date(props.data);
        dateX = formatInTimeZone(dateX, "America/Sao_Paulo", " dd/MM HH:mm");

        return (
            <li className="list-group-item" key={'listGroup'}>
                <div className="row">
                    <div className="col">
                        <div className="horizontally-centered">
                            <span className="me-2 fs-12">
                                {dateX}
                            </span>
                        </div>
                        <p className="mb-0">Prêmio: <span className="opacity-75">{props.prize === null ? 'Nehum prêmio ganho' : props.prize}</span></p>
                    </div>
                </div>
            </li>
        )
    }

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <div className="col"><h5 className="mb-3">Histórico</h5></div>
            <ul className="list-group text-start mb-4">
                {!appState.userHistory || Object.keys(appState.userHistory).length === 0 ? (
                    <div>
                        <PageLoader color={dataApplication.customizacao.primaryColor} width={50}></PageLoader>
                    </div>
                ) : (
                    <>
                        {appState.userHistory && appState.userHistory.length > 0 ? (
                            <div>
                                {appState.userHistory && Object.keys(appState.userHistory).map(key => (
                                    <ItemList key={'itemList' + key} props={appState.userHistory[key]}></ItemList>
                                ))}
                            </div>
                        ) : (
                            <li className="list-group-item py-4">
                                <div className="row">
                                    <div className="col">
                                        <p className="text-center small my-2">Não foi encontrado nenhum resultado</p>
                                    </div>
                                </div>
                            </li>
                        )}
                    </>
                )}
            </ul>
            <div className="text-center">
                <button className="btn primaryColor primaryColorText mt-3" type="button" onClick={() => setDrawPage('draw')}>Ver sorteio em andamento</button>
            </div>
        </motion.div>
    )
}

const WithQRCode = () => {
    const { dataApplication } = useGlobalState();
    const { appState } = useAppState();

    return (
        <AnimatePresence mode={'wait'}>
            {appState.validado === 1 ? (
                <>
                    {appState.drawMode === 2 ? (
                        <AnimatePresence mode="wait">
                            <HandleDraw key={'HandleDraw'}></HandleDraw>
                        </AnimatePresence>
                    ) : (
                        <HandlePrize key={'HandlePrize'}></HandlePrize>
                    )}
                </>
            ) : (
                <motion.div key={'notValidated'} initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transition: { duration: 0.75 } }}
                    exit={{ opacity: 0 }}>
                    {appState.title.status && <div className="col"><h5 className="mb-3"><TextWithLineBreaks text={appState.title.value}></TextWithLineBreaks></h5></div>}
                    {appState.description.status && <div className="col"><p><TextWithLineBreaks text={appState.description.value}></TextWithLineBreaks></p></div>}
                    <div className="col">
                        <button className="btn noClickButton my-3">
                            Não validado <FontAwesomeIcon icon={faCircleXmark} size={'lg'} />
                        </button>
                        <div className="mt-3">
                            <QRCode className="bg-white p-3 qrcodeContainer" value={window.btoa('flex-id' + dataApplication.visitante.visitante_id)} />
                        </div>
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    )
}


export default PrizeDraw