import React, { useEffect, useState, useRef } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import QRCode from "react-qr-code";
import { faQrcode, faArrowRightFromBracket, faMobileScreen, faUserGroup, faRankingStar, faAddressCard, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from "react-i18next";
import { useGlobalState } from '../../../hooks/useCustomization';
import { useAppState } from '../context/AppContext.js'
import { motion } from "framer-motion"
import axios from 'axios'
import { domainConfig } from "../../../assets/config"
import useAuth from "../../../hooks/useAuth";
import photoFrame from "../assets/img/photo-frame.png"
import { qrcodeDownload, getRandomInterval } from "./../../../components/Functions.js"

const Home = () => {
    const { dataApplication, dispatch, updateData } = useGlobalState();
    const { appState } = useAppState()
    const navigate = useNavigate();
    const location = useLocation();
    const [modalShow, setModalShow] = useState(false);
    const qrCodeRef = useRef(null);
    const { signOut } = useAuth();
    const { t, i18n } = useTranslation();
    const json = localStorage.getItem(dataApplication.token + '&customization_id=' + appState.customizacao_id) ? JSON.parse(localStorage.getItem(dataApplication.token + '&customization_id=' + appState.customizacao_id)) : { 'language': 'pt', 'tutorial': true }

    const getData = () => {
        axios
            .get(`/api/eventos/participant/${dataApplication.visitante.visitante_id}?evento_id=${dataApplication.evento_id}&customizacao_id=${appState.customizacao_id}`)
            .then(function (response) {
                dispatch({
                    type: 'setVisitante', payload: response.data.message
                });
            })
            .catch(function (error) {
                localStorage.setItem(dataApplication.token, "")
                navigate(`/login${location.search}`)
            });
    }

    useEffect(() => {
        i18n.changeLanguage(json.language)
        getData()
        const intervalId = setInterval(() => {
            getData()
        }, getRandomInterval(10000, 15000));
        return () => clearInterval(intervalId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateData])
    
    return (
        <motion.div className="default-box justify-content-start"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 1 } }}
            exit={{ opacity: 0 }}>
            <div className="box-full">
                <div className="homeCardsFirst">
                    <div className="frameProfile">
                        <div className="vertically-centered">
                            <div className="col-md-12">
                                <img className="avatarProfile2" src={dataApplication.visitante.img ? domainConfig.imageServer + "/customizacao/" + appState.customizacao_id + '/' + dataApplication.visitante.img : photoFrame} alt="" />
                            </div>
                            <div className="my-2">
                                {dataApplication.customizacao.form.fieldsToShow.map((inputID, index) => {
                                    const field = dataApplication.customizacao.form.fields.find(field => field.inputID === inputID);
                                    if (field && dataApplication.visitante.json[field.inputID]) {
                                        return (
                                            <h5 key={'contactsParticipantDetails' + index}>
                                                {dataApplication.visitante.json[field.inputID].value}
                                            </h5>
                                        );
                                    }
                                    return ""
                                })}
                            </div>
                            <div>
                                <div className="vertically-centered pe-4 frameProfileSeparator">
                                    <p className="mb-1">{t('t57')}</p>
                                    <h5 className="mb-0">{dataApplication.visitante.total_contatos}</h5>
                                </div>
                                <div className="vertically-centered ps-4">
                                    <p className="mb-1">{t('t62')}</p>
                                    <h5 className="mb-0">#{dataApplication.visitante.ranking}</h5>
                                </div>
                            </div>
                        </div>
                        <div className="iconSize leaveIconHome" onClick={(() => signOut())}>
                            <FontAwesomeIcon icon={faArrowRightFromBracket} />
                        </div>
                        <div className="iconSize codeIconHome" onClick={(() => setModalShow(true))}>
                            <FontAwesomeIcon icon={faQrcode} width="30px" height="30px" />
                        </div>
                    </div>
                </div>
                <div className="homeCards" onClick={(() => navigate(`/qrcode${location.search}`))}>
                    <div className="homeCardsIcon">
                        <FontAwesomeIcon icon={faMobileScreen} />
                    </div>
                    <div className="ms-4 text-start">
                        <h5>{t('t69')}</h5>
                        <p className='mb-0'>{t('t74')}</p>
                        <div className="homeCardsIconArrow">
                            <FontAwesomeIcon icon={faArrowRight} />
                        </div>
                    </div>
                </div>
                <div className="homeCards" onClick={(() => navigate(`/contatos${location.search}`))}>
                    <div className="homeCardsIcon">
                        <FontAwesomeIcon icon={faUserGroup} />
                    </div>
                    <div className="ms-4 text-start">
                        <h5>{t('t57')}</h5>
                        <p className='mb-0'>{t('t75')}</p>
                        <div className="homeCardsIconArrow">
                            <FontAwesomeIcon icon={faArrowRight} />
                        </div>
                    </div>
                </div>
                <div className="homeCards" onClick={(() => navigate(`/ranking${location.search}`))}>
                    <div className="homeCardsIcon">
                        <FontAwesomeIcon icon={faRankingStar} />
                    </div>
                    <div className="ms-4 text-start">
                        <h5>{t('t62')}</h5>
                        <p className='mb-0'>{t('t76')}</p>
                        <div className="homeCardsIconArrow">
                            <FontAwesomeIcon icon={faArrowRight} />
                        </div>
                    </div>
                </div>
                <div className="homeCards mb-0" onClick={(() => navigate(`/perfil${location.search}`))}>
                    <div className="homeCardsIcon">
                        <FontAwesomeIcon icon={faAddressCard} />
                    </div>
                    <div className="ms-4 text-start">
                        <h5>{t('t78')}</h5>
                        <p className='mb-0'>{t('t77')}</p>
                        <div className="homeCardsIconArrow">
                            <FontAwesomeIcon icon={faArrowRight} />
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={modalShow}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton onHide={(() => setModalShow(false))}>
                    <Modal.Title>{t('t86')}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <QRCode ref={qrCodeRef} className="bg-white p-3 qrcodeContainer mb-3 mx-auto" value={domainConfig.aplicativos + "/contatos/adicionar/" + encodeURIComponent(btoa(`flexid=` + dataApplication.visitante.visitante_id)) + "?token=" + dataApplication.token} />
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn primaryColorT" onClick={(() => setModalShow(false))}>
                        {t('t55')}
                    </button>
                    <button className="primaryColor primaryColorText btn" onClick={() => qrcodeDownload(qrCodeRef, dataApplication.visitante.email)}>{t('t91')}</button>
                </Modal.Footer>
            </Modal>
        </motion.div>
    )
}

export default Home