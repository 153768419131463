/* eslint-disable no-unused-vars */

import { useState, useEffect, useMemo, useRef } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { differenceInMilliseconds } from "date-fns";
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useGlobalState } from '../../../hooks/useCustomization.js';
import { TextWithLineBreaks } from "../../../components/Functions.js"
import { useAppState } from '../context/AppContext.js'
import correctAnswer from "../../../assets/sounds/11334109_correct-answer_by_lucafrancini_preview.mp3"
import wrongAnswer from "../../../assets/sounds/6786335_wrong-4_by_lucafrancini_preview.mp3"
import { domainConfig } from "../../../assets/config.js"
import Countdown from "./Countdown.js"

const Game = () => {
    const { dataApplication } = useGlobalState();
    const { appState, definitions, setStart, score, setScore, isPlaying, currentPlayer, setCurrentPlayer } = useAppState();
    const [gameCards, setGameCards] = useState([]);
    const [firstCard, setFirstCard] = useState(null);
    const [secondCard, setSecondCard] = useState(null);
    const [lockBoard, setLockBoard] = useState(false);
    const [dateUnix, setDateUnix] = useState(new Date())
    const [matches, setMatches] = useState(0);
    const [gameStarted, setGameStarted] = useState(true);
    const audioCorrect = useRef(new Audio(correctAnswer));
    const audioWrong = useRef(new Audio(wrongAnswer));

    const handleTimeLimit = () => {
        setLockBoard(true);
        if (appState.answerFeedback && appState.sound) {
            audioWrong.current.play();
        }
        let newScore = JSON.parse(JSON.stringify(score))
        let milliseconds = differenceInMilliseconds(new Date(), dateUnix)
        newScore.time = milliseconds
        handleGameEnd(newScore)
    }

    const shuffle = (array) => {
        const arrayCopy = [...array];
        let data = [];
        // Iterate over the shuffled array
        arrayCopy.forEach((value, index) => {
            value.forEach((element, index) => {
                data.push({
                    value: element.url,
                    pair: element.pair,
                    id: index,
                    flipped: false,
                    matched: false,
                    matchedBy: null
                });
            });
        });;
        let shuffledArray = data.sort(() => Math.random() - 0.5);
        setGameCards(shuffledArray);
    };

    const flipCard = (index) => {
        if (lockBoard) return;
        if (gameCards[index].flipped) return;

        const updatedCards = [...gameCards];
        updatedCards[index].flipped = true;
        setGameCards(updatedCards);
        if (!firstCard) {
            setFirstCard({ index, value: updatedCards[index].value, pair: updatedCards[index].pair });
            return;
        }
        setSecondCard({ index, value: updatedCards[index].value, pair: updatedCards[index].pair });
        setLockBoard(true);
        checkForMatch({ index, value: updatedCards[index].value, pair: updatedCards[index].pair });
    };

    const checkForMatch = (second) => {
        let isMatch = firstCard.pair === second.pair;
        isMatch ? disableCards(firstCard.index, second.index) : unflipCards(firstCard.index, second.index);
    };

    const disableCards = (index1, index2) => {
        const updatedCards = [...gameCards];
        updatedCards[index1].matched = true;
        updatedCards[index2].matched = true;
        updatedCards[index1].matchedBy = currentPlayer;
        updatedCards[index2].matchedBy = currentPlayer;
        setGameCards(updatedCards);
        resetBoard();
        setMatches((prevState) => prevState + 1)
        let newScore = JSON.parse(JSON.stringify(score))
        if (currentPlayer === 1) {
            newScore.player1.value = newScore.player1.value += 1
        } else {
            newScore.player2.value = newScore.player2.value += 1
        }
        setScore(newScore);
        setTimeout(() => {
            if (appState.sound) {
                if (!audioCorrect.current.paused) {
                    audioCorrect.current.pause();
                    audioCorrect.current.currentTime = 0;
                }
                audioCorrect.current.play();
            }
        }, 750);
        if ((matches + 1) * 2 >= appState.memoryGame.cards.value.length) {
            let milliseconds = differenceInMilliseconds(new Date(), dateUnix)
            newScore.time = milliseconds
            handleGameEnd(newScore)
        }
    };

    const handleGameEnd = (newScore) => {
        axios.post(`/api/eventos/history/`, {
            visitante_id: dataApplication.visitante.visitante_id,
            evento_id: dataApplication.evento_id,
            customizacao_id: appState.customizacao_id,
            json: JSON.stringify(newScore),
        }).then(function (response) {
            setTimeout(() => {
                setStart(appState.showRanking ? 3 : 4)
            }, appState.memoryGame?.endGameTimer ? appState.memoryGame.endGameTimer : 3000);
        }).catch(function (error) {
            console.log(error)
        })
    }

    const unflipCards = (index1, index2) => {
        setTimeout(() => {
            if (appState.sound) {
                if (!audioWrong.current.paused) {
                    audioWrong.current.pause();
                    audioWrong.current.currentTime = 0;
                }
                audioWrong.current.play();
            }
            const updatedCards = [...gameCards];
            updatedCards[index1].flipped = false;
            updatedCards[index2].flipped = false;
            setGameCards(updatedCards);
            switchPlayer()
            resetBoard();
        }, appState.memoryGame?.flipCardTimer ? appState.memoryGame.flipCardTimer : 1500);
    };

    const resetBoard = () => {
        setFirstCard(null);
        setSecondCard(null);
        setLockBoard(false);
    };

    const switchPlayer = () => {
        if (definitions.players === 2) {
            setCurrentPlayer(currentPlayer === 1 ? 2 : 1);
        }
    };

    const memorizedCards = useMemo(() => (
        gameCards.map((card, index) => (
            <CardFlip key={card.id} card={card} index={index} flipCard={flipCard} currentPlayer={currentPlayer} definitions={definitions} />
        ))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [gameCards]);

    useEffect(() => {
        if (!isPlaying) {
            audioCorrect.current.volume = 0;
            audioWrong.current.volume = 0;
        } else {
            audioCorrect.current.volume = 0.7;
            audioWrong.current.volume = 0.7;
        }
    }, [isPlaying])

    useEffect(() => {
        if (gameStarted) {
            shuffle([appState.memoryGame.cards.value]);
            setDateUnix(new Date())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gameStarted]);

    return (
        <div className={`row memoryGameBox ${appState.timer.status ? 'mt-0' : 'm-auto'}`}>
            {appState.memoryGame?.gameText?.status ? (
                <div className="col-12">
                    <h5 className="mb-4 baseColorText"><TextWithLineBreaks text={appState.memoryGame.gameText.value}></TextWithLineBreaks></h5>
                    {(appState.timer.status) ? (
                        <Countdown handleTimeLimit={handleTimeLimit} />
                    ) : ("")}
                </div>
            ) : ("")}
            {memorizedCards}
        </div>
    );
}

const CardFlip = ({ card, index, flipCard, currentPlayer, definitions }) => {
    const { appState } = useAppState();

    return (
        <div className={`cardFlip col-${appState.memoryGame.cards.cardsPerRow} ${card.flipped ? 'flipped' : ''}`}>
            <div className={`flip-card ${card.flipped ? 'flipped' : ''}`} onClick={() => flipCard(index)}>
                <img className="opacity-0" src={domainConfig.imageServer + "/customizacao/" + appState.customizacao_id + "/" + appState.memoryGame.cards.front} alt="Front" />
                <div className={`flip-card-inner 
                ${definitions.players === 2 && card.flipped && !card.matched ? currentPlayer === 1
                        ? 'flippedFirstPlayer' : 'flippedSecondPlayer' : ''
                    } ${definitions.players === 2 && card.matched ? (card.matchedBy === 1 ? 'flippedFirstPlayer' : 'flippedSecondPlayer') : ''}`}>
                    <div className="flip-card-front">
                        <img src={domainConfig.imageServer + "/customizacao/" + appState.customizacao_id + "/" + appState.memoryGame.cards.front} alt="Front" />
                    </div>
                    <div className="flip-card-back">
                        <img src={domainConfig.imageServer + "/customizacao/" + appState.customizacao_id + "/" + card.value} alt="Back" />
                    </div>
                </div>
            </div>
        </div>
    );
};


export const PlayerCard = ({ currentPlayer, score }) => {
    const { appState } = useAppState()

    const slideAnimation = {
        initial: { x: '80%', opacity: 0 },
        animate: { x: '0%', opacity: 1 },
        exit: { x: '80%', opacity: 0 },
    };

    if (currentPlayer === 1) {
        return (
            <motion.div
                className="mb-3 firstPlayerColorCard"
                initial="initial"
                animate="animate"
                exit="exit"
                variants={slideAnimation}
                transition={{ duration: 0.25 }}>
                <div className="row card-body">
                    <div className="col vertically-centered align-items-baseline">
                        <p className="mb-1">1º Jogador</p>
                        <div className="d-flex justify-content-between">
                            {appState.showScore && <small className="mb-0">Pontos: {score.player1.value}</small>}
                        </div>
                    </div>
                </div>
            </motion.div>
        )
    } else {
        return (
            <motion.div
                className="mb-3 secondPlayerColorCard"
                key="player2"
                initial="initial"
                animate="animate"
                exit="exit"
                variants={slideAnimation}
                transition={{ duration: 0.25 }}>
                <div className="row card-body">
                    <div className="col vertically-centered align-items-baseline">
                        <p className="mb-1">2º Jogador</p>
                        <div className="d-flex justify-content-between">
                            {appState.showScore && <small className="mb-0">Pontos: {score.player2.value}</small>}
                            {appState.showScore && <small className="mb-0">Tempo: {score.player2.time}</small>}
                        </div>
                    </div>
                </div>
            </motion.div>
        )
    }
}

export default Game;