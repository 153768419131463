import React, { useReducer, useContext, useState, useEffect } from 'react';
import { io } from 'socket.io-client';
import HelmetStyle from '../components/HelmetStyle.js'
import { useGlobalState } from '../../../hooks/useCustomization.js';
import { domainConfig } from "../../../assets/config.js"

let socket

const AppContext = React.createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case 'CONNECTED':
      return {
        ...state,
        isConnected: action.payload,
      }
    case 'setContext':
      return {
        ...state,
        context: action.payload,
      }
    case 'setValidado':
      return {
        ...state,
        validado: action.payload,
      }
    case 'setParticipants':
      return {
        ...state,
        participants: action.payload,
      }
    case 'setResults':
      return {
        ...state,
        results: action.payload,
      }
    case 'setQuestions':
      return {
        ...state,
        questions: action.payload,
      }
    case 'setRouletteResult':
      return {
        ...state,
        rouletteResult: action.payload,
      }
    case 'updateRoulette':
      return {
        ...state,
        roulette: action.payload
      }
    default:
      return state;
  }
};

const GameProvider = ({ children }) => {
  const { dataApplication } = useGlobalState();
  const [appState, dispatch] = useReducer(reducer, dataApplication.data);
  const [updateData, setUpdateData] = useState(0)
  const [showPresentation, setShowPresentation] = useState(false)
  const [showQRCode, setShowQRCode] = useState(false)
  const [isPlaying, setIsPlaying] = useState(true)

  useEffect(() => {
    if (dataApplication && dataApplication.presenter) {
      socket = io(domainConfig.socketAPI + '?aplicativo_id=' + dataApplication.data.aplicativo_id + '&customizacao_id=' + dataApplication.data.customizacao_id, {
        extraHeaders: {
          'x-event-id': dataApplication.evento_id, // Include event_id in headers
        },
        autoConnect: true,
        withCredentials: true
      });
      socket.on('connect', () => {
        console.log('connect')
        dispatch({ type: 'CONNECTED', payload: true });
      });
      socket.on('login', (result) => {
        dispatch({ type: 'setContexto', payload: result });
      });
      socket.on('disconnect', () => {
        console.log('disconnect')
        dispatch({ type: 'CONNECTED', payload: false });
      });
      socket.on('updateData', () => {
        console.log('updateData')
        setUpdateData((prevState) => prevState + 1)
      });
      socket.on('getData', (result) => {
        let data = processParticipants(result.participants, result.results, appState.roulette)
        dispatch({ type: 'setResults', payload: data.results });
        dispatch({ type: 'setParticipants', payload: data.participants });
      });
      socket.emit('login');
      return () => {
        if (socket) {
          socket.disconnect();
          console.log('Socket disconnected');
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppContext.Provider value={{
      appState, dispatch, showPresentation, updateData, setUpdateData, showQRCode, setShowQRCode,
      setShowPresentation, isPlaying, setIsPlaying
    }}>
      <HelmetStyle appState={appState} />
      {children}
    </AppContext.Provider>
  );
};

const getData = (type) => {
  socket.emit('getData', type);
};

const updateData = () => {
  socket.emit('updateData');
};

const updateRoulette = (values) => {
  socket.emit('updateRoulette', values);
};

const updateRouletteIndex = (draggedItemIndex, targetItemIndex) => {
  socket.emit('updateRouletteIndex', draggedItemIndex, targetItemIndex);
};

const postRouletteResult = (values, visitante_id, activatePrizes) => {
  socket.emit('postRouletteResult', values, visitante_id, activatePrizes);
};


export {
  AppContext,
  GameProvider,
  updateData,
  updateRouletteIndex,
  updateRoulette,
  getData,
  postRouletteResult,
};

export const useAppState = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppState must be used within a GlobalStateProvider');
  }
  return context;
};

function processParticipants(participants, results) {
  let newHistory = JSON.parse(JSON.stringify(results))

  newHistory = newHistory.map(historyItem => {
    let parsedItem = JSON.parse(historyItem.json);
    parsedItem.data = historyItem.data;
    parsedItem.visitante_id = historyItem.visitante_id;
    return parsedItem;
  })

  participants.forEach(participant => {
    participant.participantDetails = JSON.parse(participant.json);
    const transformedDetails = {};

    for (const key in participant.participantDetails) {
      const detail = participant.participantDetails[key];
      transformedDetails[detail.label] = detail.value;
    }
    Object.assign(participant, transformedDetails);
    participant.history = []

    newHistory.forEach(historyItem => {
      if (historyItem.visitante_id === participant.visitante_id) {
        participant.history.push(historyItem);
        Object.keys(participant.participantDetails).forEach(key => {
          let element = participant.participantDetails[key]
          let newElements = { [element.label]: element.value }
          Object.assign(historyItem, newElements);
        });
      }
    });
  });

  return { 'participants': participants, 'results': newHistory };
}
