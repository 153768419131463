

import React, { useState, useEffect, useRef } from 'react'
import { Tabs, Tab, Modal, Form, Row, Col, Tooltip, OverlayTrigger } from 'react-bootstrap'
import * as Yup from "yup";
import { Formik } from "formik"
import { AnimatePresence, motion } from "framer-motion"
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowLeft,
    faCircleInfo,
    faEdit,
    faFileExport,
    faQrcode,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import { NavbarPresenter } from './components/Navbar.js'
import { useGlobalState } from '../../hooks/useCustomization.js';
import { domainConfig } from "../../assets/config.js"
import { useAppState, getData, registerParticipants, editParticipant, deleteParticipant } from "./context/AppContext.js"
import QRCode from "react-qr-code";
import { TextWithLineBreaks, ColumnSortingTable, qrcodeDownload } from "../../components/Functions.js"
import { PageLoader, ConfirmDialog } from "../../components/Elements"
import { FormComponent } from '../../Register.js'
import first from "./assets/img/1_place_icon.png"
import second from "./assets/img/2_place_icon.png"
import third from "./assets/img/3_place_icon.png"

const NetworkingAdmin = () => {
    const { dataApplication, handleExit, blockScreen } = useGlobalState();
    const { appState, updateData } = useAppState();

    useEffect(() => {
        if (appState.isConnected) {
            getData()
            const intervalId = setInterval(() => {
                getData()
            }, 15000);
            return () => clearInterval(intervalId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateData, appState.isConnected]);

    if (appState.participants) {
        if (!blockScreen) {
            return (
                <>
                    <motion.nav
                        className="position-fixed w-100 px-3 justify-content-between navbar navbar-expand"
                        key={'appsNavbar'}
                        initial={{ y: -100 }}
                        animate={{ y: 0 }}
                        transition={{ duration: 0.5 }}
                        exit={{ y: -100 }}>
                        <NavbarPresenter />
                    </motion.nav>
                    <motion.div className='presenter-box container-lg' key="mainDiv" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                        <div className="box">
                            <div className="logoBox">
                                <div>
                                    <div className="backButton" onClick={() => handleExit()}><FontAwesomeIcon icon={faArrowLeft} size={'lg'} /></div>
                                </div>
                                <div className="logoBoxChildMiddle">
                                    <div className="pb-3">
                                        <h5 className="mb-1"><TextWithLineBreaks text={appState.titulo}></TextWithLineBreaks></h5>
                                        <span className="badge primaryColor primaryColorText mb-5 fw-normal">{appState.nomeAplicativo}</span>
                                    </div>
                                </div>
                                <div className="logoBoxHideButton">
                                    <div className="backButton"></div>
                                </div>
                            </div>
                            <Tabs
                                defaultActiveKey="k1"
                                transition={true}
                                id="noanim-tab-example"
                                className="mb-4"
                            >
                                <Tab eventKey="k1" title={`Painel`}>
                                    <AnimatePresence mode="wait">
                                        <Painel></Painel>
                                    </AnimatePresence>
                                </Tab>
                                <Tab eventKey="k4" title={`Participantes (${appState.participants.length})`}>
                                    <Participants></Participants>
                                </Tab>
                            </Tabs>
                        </div>
                    </motion.div>
                </>
            )
        } else {
            return (
                <div className='default-box'>
                    <div className={`box-full`}>
                        <img src={domainConfig.imageServer + "/evento/" + dataApplication.evento_id + "/" + dataApplication.customizacao.logo} alt="Logo" className="App-logo"></img>
                        <p>Essa tela só está disponível<br></br>para dispositivos maiores que 600px</p>
                    </div>
                </div>
            )
        }
    } else {
        return (
            <motion.div className='default-box' key={'loaderDiv'}>
                <PageLoader color={dataApplication.customizacao.primaryColor} width={75}></PageLoader>
            </motion.div>
        )
    }
}

const Painel = () => {
    const { dataApplication, setLoading } = useGlobalState();
    const { appState } = useAppState();

    function handleFormParticipants(values, actions) {
        actions.setSubmitting(false)
        actions.resetForm();
        values.subject = appState.email.createAccount.subject
        values.html = appState.email.createAccount.html
        setLoading(true)
        registerParticipants(values, dataApplication.token)
    }

    return (
        <motion.div className="row" initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.25 } }}
            exit={{ opacity: 0 }}>
            <div className="col-md-8 col-sm-12 offset-md-2 mt-3">
                <Formik
                    validationSchema={Yup.object().shape({
                        emails: Yup.string()
                            .required("Campo obrigatório"),
                    })}
                    onSubmit={handleFormParticipants}
                    validateOnChange={false}
                    validateOnBlur={true}
                    enableReinitialize
                    initialValues={{
                        emails: '',
                        sendEmail: false
                    }}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        touched,
                        isValid,
                        errors,
                        dirty,
                        isSubmitting,
                        setSubmitting,
                        setFieldValue,
                    }) => (
                        <Form noValidate onSubmit={handleSubmit} className="text-start">
                            <Row className="mx-0">
                                <Form.Group as={Col} sm={12} className='my-3'>
                                    <Form.Label>
                                        Adicionar participantes
                                        <OverlayTrigger
                                            placement={"bottom"}
                                            overlay={<Tooltip>Adicione os emails dos participantes separado por linha. Emails repetidos serão ignorados automaticamente.</Tooltip>}
                                        >
                                            <FontAwesomeIcon className="vertically-centered px-1 primaryColorT" size={"lg"} icon={faCircleInfo} />
                                        </OverlayTrigger>
                                    </Form.Label>
                                    <Form.Control
                                        style={{ height: "250px" }}
                                        type="emails"
                                        as="textarea"
                                        name={'emails'}
                                        value={values.emails}
                                        onChange={handleChange}
                                        isInvalid={!!errors.emails}
                                        isValid={touched.emails && !errors.emails}
                                    />
                                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.emails}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} sm={12} className='mb-3'>
                                    <Form.Check
                                        className="checkboxFontSize"
                                        checked={values.sendEmail ? true : false}
                                        type="checkbox"
                                        name={"sendEmail"}
                                        onChange={(e) => {
                                            setFieldValue(
                                                'sendEmail',
                                                values.sendEmail ? false : true
                                            );
                                        }}
                                        label={`Enviar convite por email`}
                                        isInvalid={!!errors.sendEmail}
                                        feedback={errors.sendEmail}
                                        feedbackType="invalid">
                                    </Form.Check>
                                </Form.Group>
                            </Row>
                            <div className="text-center">
                                <button className="btn primaryColor primaryColorText mx-0" type="submit" disabled={isSubmitting}>Adicionar</button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </motion.div>
    )
}

const Participants = () => {
    const { dataApplication, setLoading } = useGlobalState();
    const { appState } = useAppState();
    const [fields, setFields] = useState(false);
    const [triggerExport, setTriggerExport] = useState(false);
    const [showModal, setShowModal] = useState(false)
    const [modalData, setModalData] = useState(false)
    const [showModalQR, setShowModalQR] = useState(false)
    const [modalDataQR, setModalDataQR] = useState(false)
    const [deleteParticipantID, setDeleteParticipantID] = useState(0);

    const handleClose = () => setDeleteParticipantID(0);

    const handleConfirm = () => {
        setLoading(true)
        deleteParticipant(deleteParticipantID);
        handleClose()
    };
    const qrCodeRef = useRef()

    const openParticipant = (value) => {
        setShowModal(true);
        setModalData(value)
    }

    const showQRCode = (value) => {
        setShowModalQR(true);
        setModalDataQR(value)
    }

    useEffect(() => {
        let fieldsX = []
        fieldsX.push({
            Header: 'Email',
            accessor: 'email'
        })
        fieldsX.push({
            Header: 'Status',
            accessor: 'status',
            Cell: (row) => {
                return row.value > 0 ? "Ativo" : "Não cadastrado"
            }
        })
        fieldsX.push({
            Header: 'Data de registro',
            accessor: 'data_visitacao'
        })
        fieldsX.push({
            Header: 'Data último acesso',
            accessor: 'data_ultimo_acesso'
        })
        fieldsX.push({
            Header: 'Total de contatos',
            accessor: 'total_contatos',
        })
        fieldsX.push({
            Header: 'Data último contato',
            accessor: 'data_ultimo_contato',
        })
        fieldsX.push({
            Header:
                <OverlayTrigger overlay={<Tooltip>Exportar</Tooltip>}>
                    <button
                        className="btn primaryColor primaryColorText exportButton float-end"
                        onClick={() => {
                            setTriggerExport(true);
                        }}
                    >
                        <FontAwesomeIcon className="vertically-centered cursor-pointer px-1 primaryColorT" size={"lg"} icon={faFileExport} />
                    </button>
                </OverlayTrigger>,
            accessor: 'editar',
            disableSortBy: true,
            Filter: false,
            Cell: (cell) => {
                return (
                    <div className="text-center" style={{ minWidth: "75px" }}>
                        <OverlayTrigger overlay={<Tooltip>Remover</Tooltip>}>
                            <FontAwesomeIcon onClick={() => setDeleteParticipantID(cell.row.original.visitante_id)} icon={faTrash} size={'lg'} className="cursor-pointer primaryColorT me-2" />
                        </OverlayTrigger>
                        <OverlayTrigger overlay={<Tooltip>QR Code</Tooltip>}>
                            <FontAwesomeIcon onClick={() => showQRCode(cell.row.original)} icon={faQrcode} size={'lg'} className="cursor-pointer primaryColorT me-2" />
                        </OverlayTrigger>
                        <OverlayTrigger overlay={<Tooltip>Editar</Tooltip>}>
                            <FontAwesomeIcon onClick={() => openParticipant(cell.row.original)} icon={faEdit} size={'lg'} className="cursor-pointer primaryColorT" />
                        </OverlayTrigger>

                    </div >
                );
            },
        })
        setFields(fieldsX)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (fields) {
        return (
            <div>
                <div className="tablePresenter">
                    <ColumnSortingTable
                        columns={fields}
                        data={appState.participants}
                        title={dataApplication.titulo}
                        getCellProps={(cellinfo) => ({
                            style: {
                                textAlign: cellinfo.column.id === "data_visitacao" || cellinfo.column.id === "data_ultimo_contato" || cellinfo.column.id === "data_ultimo_acesso" || cellinfo.column.id === "total_contatos" ? "center" : "left",
                            },
                        })}
                        triggerExport={triggerExport}
                        setTriggerExport={setTriggerExport}
                    />
                </div>
                <ParticipantHistoryModal showModal={showModal} setShowModal={setShowModal} modalData={modalData} setModalData={setModalData} />
                <ConfirmDialog
                    show={deleteParticipantID > 0 ? true : false}
                    title={"Confirmar ação"}
                    body={"Ao remover o participante o QRcode dele se tornará inválido e seus contatos serão perdidos"}
                    handleClose={handleClose}
                    handleConfirm={handleConfirm}
                />
                <Modal show={showModalQR}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Header closeButton onHide={(() => setShowModalQR(false))}>
                        <Modal.Title>QR Code</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        <QRCode ref={qrCodeRef} className="bg-white p-3 qrcodeContainer" value={domainConfig.aplicativos + "/contatos/adicionar/" + encodeURIComponent(btoa(`flexid=` + modalDataQR.visitante_id)) + "?token=" + dataApplication.token} />
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn primaryColor primaryColorText" onClick={() => qrcodeDownload(qrCodeRef, modalDataQR.email)}>
                            Download
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export const RankingModal = () => {
    const { dataApplication } = useGlobalState();
    const { appState, setShowPresentation } = useAppState();
    const { t } = useTranslation();

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape' || event.keyCode === 27) { // Check if the "End" key was pressed
                setShowPresentation(false);
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="logoBox">
                <div className="logoBoxChildMiddle">
                    <img src={domainConfig.imageServer + "/evento/" + dataApplication.evento_id + "/" + dataApplication.customizacao.logo} alt="Logo" className="App-logo pb-0"></img>
                    {appState.apresentation.title.status && <h5 className="my-5"><TextWithLineBreaks text={appState.apresentation.title.value}></TextWithLineBreaks></h5>}
                </div>
            </div>
            <div className='presenter-box container mt-3 justify-content-start'>
                <div className="tablePresenter text-center">
                    <div className="ranking-group">
                        {appState.ranking.length > 0 ? (
                            <>
                                {appState.ranking.map((props, index) => {
                                    index++
                                    return (
                                        <div className="rankingCard primaryColor" key={'rankingC' + index}>
                                            {(() => {
                                                switch (index) {
                                                    case 1:
                                                        return <img src={first} alt=""></img>
                                                    case 2:
                                                        return <img src={second} alt=""></img>
                                                    case 3:
                                                        return <img src={third} alt=""></img>
                                                    default:
                                                        return ""
                                                }
                                            })()}
                                            <div>
                                                <img className="avatarProfile3 my-2 ms-3" src={domainConfig.imageServer + "/customizacao/" + appState.customizacao_id + '/' + props.img} alt="" />
                                            </div>
                                            <div className="rankingCardChildren">
                                                {dataApplication.customizacao.form.fieldsToShow.map((inputID, index) => {
                                                    const field = dataApplication.customizacao.form.fields.find(field => field.inputID === inputID);
                                                    if (field && props.json[field.inputID]) {
                                                        return (
                                                            <p key={'contactsParticipantDetails' + index} className={index < 4 ? "lineH mb-2 rankingText primaryColorText" : "lineH mb-0 rankingText primaryColorText"}>{props.json[field.inputID].value}</p>
                                                        );
                                                    }
                                                    return ""
                                                })}
                                                <div>
                                                    <small className="mb-0 primaryColorText">{props.total_contatos} {t('t57')}</small>
                                                    <p className="mb-0 primaryColorText">{index < 4 ? ("") : (index + '#')}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                )}
                            </>
                        ) : (
                            <div className="ranking-group pt-2">
                                <p>Nenhum contato encontrado</p>
                            </div>
                        )}
                    </div>
                </div>
            </div >
        </>
    )
}

const ParticipantHistoryModal = ({ showModal, setShowModal, modalData, setModalData }) => {
    const { dataApplication, setLoading } = useGlobalState();
    const { appState } = useAppState()
    const formikRef = useRef(null);

    const submitFormExternally = () => {
        if (formikRef.current) {
            formikRef.current.submitForm();
        }
    };

    function registerUser(values, actions) {
        let json = {}
        let email
        Object.entries(values).forEach(entry => {
            const field = dataApplication.customizacao.form.fields.find(field => field.inputID === parseInt(entry[0]));
            if (field) {
                if (field.validation === 'email') {
                    email = entry[1]
                }
                json[entry[0]] = { 'label': field.label, 'value': entry[1] }
            }
        })
        json = JSON.stringify(json)
        setLoading(true)
        editParticipant(json, email, modalData.visitante_id)
        setModalData(false)
    }

    return (
        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showModal}
            onHide={() => setShowModal(false)}
        >
            <Modal.Header closeButton onHide={() => setShowModal(false)}>
                <Modal.Title>Editar participante</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="mb-4">
                    <p className="mb-0 modalDataFields">
                        Status: <span>{modalData.status > 0 ? "Ativo" : "Não cadastrado"}</span>
                    </p>
                    <p className="mb-0 modalDataFields">
                        Data de registro: <span>{modalData.data_visitacao}</span>
                    </p>
                    <p className="mb-0 modalDataFields">
                        Data último acesso: <span>{modalData.data_ultimo_acesso}</span>
                    </p>
                    <p className="mb-0 modalDataFields">
                        Total de contatos: <span>{modalData.total_contatos}</span>
                    </p>
                    <p className="mb-0 modalDataFields">
                        Data último contato: <span>{modalData.data_ultimo_contato}</span>
                    </p>
                </div>
                <FormComponent formikRef={formikRef} registerUser={registerUser} editValues={true} customizacao_id={appState.customizacao_id} visitanteData={modalData.participantDetails}></FormComponent>

            </Modal.Body>
            <Modal.Footer>
                <button className="btn primaryColor primaryColorText" onClick={submitFormExternally}>
                    Continuar
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default NetworkingAdmin