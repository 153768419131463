import React from 'react'
import ReactDOM from 'react-dom/client'
import axios from 'axios'
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async"
import { CustomizationProvider } from './contexts/CustomizationContext';
import { domainConfig } from "./assets/config.js"
import { App } from './App'
import reportWebVitals from './reportWebVitals'
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'bootstrap/dist/css/bootstrap.css'
import './assets/css/App.css'

const root = ReactDOM.createRoot(document.getElementById('root'))
axios.defaults.baseURL = domainConfig.aplicativosAPI
axios.defaults.withCredentials = true;

root.render(
  <BrowserRouter>
    <HelmetProvider>
      <CustomizationProvider>
        <App />
      </CustomizationProvider>
    </HelmetProvider>
  </BrowserRouter >
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
